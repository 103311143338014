.events-calendar-wrapper {
  background-color: bg-color(reverse);
  border-bottom: none;
  position: relative;
  overflow: hidden; //clear-fix
  min-height: 477px;
  height: 100%;

  &:hover {
    box-shadow: 0 0 10px 0 rgba(0,0,0,.3);
  }
}

.events-calendar-title {
  padding: 15px;
  background-color: bg-color(primary);
  .calendar-title-text {
    margin: 0;
    width: 100%;
    color: text-color(reverse);
    line-height: 1;
    text-align: center;
    font-weight: normal;
  }
}

.events-calendar-nav {
  height: 33px;
  border: none;
  cursor: pointer;
  padding-right: 15px;
  position: absolute;
  background-color: bg-color(reverse);
  transition: left .4s, right .4s;
  &[data-calendar-direction="prev"] {
    left: 0;
    right: auto;
    transform: rotate(180deg);
    padding-bottom: 33px;
    &:hover {
      left: -5px;
    }
  }
  &[data-calendar-direction="next"] {
    left: auto;
    right: 0;
    padding-top: 9px;
    &:hover {
      right: -5px;
    }
  }
}

.events-calendar-container {
  transition: left .3s linear .3s;
  position: absolute;
}

.events-calendar-month {
  text-align: center;
  font-weight: normal;
  transform: translateX(-50%);
  margin-left: 50%;
}

.events-calendar-days-header {
  width: 100%;
  overflow: hidden; //clear-fix
  .days-header-item {
    float: left;
    padding: 6px 0;
    text-align: center;
    width: calc(100%/7);
    border: 1px solid rgba(0, 0, 0, .125);
    border-right: none;
    font-weight: bold;
    color: $black;
    &:last-of-type {
      border-right: 1px solid rgba(0, 0, 0, .125);
    }
  }
}

.events-calendar-days-wrapper {
  overflow: hidden; //clear-fix
}

.events-calendar-days-item {
  float: left;
  height: 100%;
  position: relative;
  .events-calendar-day {
    float: left;
    height: 53px;
    width: calc(100%/7);
    border: 1px solid rgba(0, 0, 0, .125);
    border-right: none;
    border-top: none;
    &.empty-day {
      &.colspan-7 {
        width: calc(100% - 1px);
      }
      &.colspan-6 {
        width: calc(100%/7*6);
      }
      &.colspan-5 {
        width: calc(100%/7*5);
      }
      &.colspan-4 {
        width: calc(100%/7*4);
      }
      &.colspan-3 {
        width: calc(100%/7*3);
      }
      &.colspan-2 {
        width: calc(100%/7*2);
      }
      &.last-day-of-row {
        border-right: 1px solid rgba(0, 0, 0, .125);
      }
      &.first-day-of-row {
        border-left: 1px solid rgba(0, 0, 0, .125);
      }
    }
    &:last-child,
    &:last-of-type,
    &.last-day-of-row {
      border-right: 1px solid rgba(0, 0, 0, .125);
    }
    &:first-child,
    &.first-day-of-row {
      border-left: 1px solid rgba(0, 0, 0, .125);
    }
    .calendar-day-number,
    .calendar-day-events {
      width: 100%;
      text-align: center;
      pointer-events: none;
    }
    &.filled-day {
      cursor: pointer;
      .calendar-day-number {
        color: text-color(reverse);
        background-color: bg-color(neutraldark);
      }
    }
    &.current-day {
      border: 1px solid $primary;
      &.filled-day {
        .calendar-day-number {
          color: text-color(reverse);
          background-color: bg-color(primary);
        }
      }
    }
    .event-dot {
      margin-right: 2.5px;
      font-size: 140%;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}