.t-unisr-organizzazione-governance {
  .main-content {
    padding-top: 30px;
    padding-right: 0;
    padding-left: 0;

    .c-two-columns {
      > .container {
        box-shadow: none;
      }

      .text-center {
        text-align: left;
      }

      .left-col, .right-col {
        .c-text {
          padding-right: 15px;
          padding-left: 15px;
        }
      }
    }

    .profile-card-item {
      &:not(:last-child) {
        padding-bottom: 0;
      }

      &:not(:first-child) {
        .profile-card-item__title {
          width: 110vw;
        }
      }
    }
  }
}