.c-research-banner, .c-text-image {
  margin: 0 -15px;

  .description {
    padding: 0 25px 26px;

    h2 {
      position: static;

      &:before {
        width: 15px;
      }
    }
  }

  .col-md-5 {
    padding: 0;
  }

  &.hideOnMobile{
    display: none;
  }

  & + .c-editorial-cards {
    margin-top: -45px;
  }

  .icon-row {
    padding: 25px;

    .icon-block {
      &:not(:first-child) {
        border-left: 0;
      }

      &:nth-child(4n + 1) {
        padding-left: 39px;
        margin-right: 24px;
        margin-bottom: -1px;

        &:not(:nth-last-child(2)) {
          &:not(:last-child) {
            border-bottom: 1px solid border-color(neutrallight);
          }
        }

        max-width: calc(50% - 25px);
        z-index: 1;

        &:not(:first-child) {
          border-top: 1px solid border-color(neutrallight);
        }
      }

      &:nth-child(4n + 2) {
        border-left: 1px solid border-color(neutrallight);
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: 25px;
        margin-bottom: 25px;
      }

      &:nth-child(4n + 3) {
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: 25px;
        margin-bottom: 25px;
        border-right: 1px solid border-color(neutrallight);
      }

      &:nth-child(4n + 4) {
        padding-top: 30px;
        padding-right: 40px;
        margin-left: 25px;
        max-width: calc(50% - 25px);
        border-top: 1px solid border-color(neutrallight);
        border-bottom: 1px solid border-color(neutrallight);
      }
    }
  }
}