.blog-carousel, .news-carousel, .events-carousel {
  a {
    &.external {
      @include icon('external', before, false);

      &:before {
        position: absolute;
        right: 40px;
        padding-right: 14px;
        font-size: rem(12);
      }
    }
  }

  .button-primary {
    background-color: bg-color(reverse);
    font-family: font-family(secondary);
    font-weight: 600;
    line-height: 20px;
    font-size: rem(14);
    padding: 10px 20px 10px 8px;
    margin-left: 15px;
    width: 310px;

    &:after {
      color: text-color(primary);
      background-color: bg-color(neutrallight);
      height: 40px;
      width: 40px;
      font-size: rem(14);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  & .container {
    &.no-padding {
      padding: 0;
    }
  }

  .carousel-top {
    padding-left: 15px;
  }

  .carousel-menu {
    align-items: baseline;

    a {
      padding-left: 60px;
      text-decoration: none;
      color: #575757;
      font-size: rem(14);
      font-weight: 600;
      line-height: 20px;
    }
  }

  .tns-ovh {
    overflow: visible;
  }

  .tns-item {
    padding-right: 30px;
    &[tabindex="-1"] {
      opacity: 0.5;
    }
  }

  .tns-controls {
    bottom: initial;
    top: -66px;

    button {
      &[data-controls] {
        width: 40px;
        height: 40px;
        padding: initial;

        &:first-of-type {
          padding-right: initial;

          &:before {
            transform: scaleX(-1);
          }
        }

        &:before {
          font-size: rem(16);
          font-weight: 900;
        }
      }

      &[disabled] {
        opacity: 0.5;
      }
    }
  }
}