.c-media {
  padding: 0 60px 60px;
  background-color: bg-color(reverse);

  img {
    vertical-align: top;
  }

  &.rounded {
    img {
      max-width: 300px;
      border-radius: 50%;
    }
  }

  & + .c-text {
    margin-top: -134px;
  }

  figcaption {
    text-align: center;
    font-family: font-family(secondary);
    font-size: rem(12);
    letter-spacing: 0;
    line-height: 21px;
    padding-top: 10px;
  }
}