header.main, header {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;

  .menu-in & {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
    position: fixed;
    top: 0;
    z-index: 6;
    background-color: bg-color(reverse);

    .scrollable {
      display: flex;
      flex-direction: column-reverse;
      position: relative;
      height: auto;
      min-height: 100%;
      justify-content: flex-end;
    }

    .upper-bar {
      transform: none;
    }
  }

  .upper-bar {
    display: none;
    font-weight: 300;
    font-size: rem(12);
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    transform: translateX(-100%);

    .row {
      align-items: center;
      justify-content: center;
    }

    .menu-in & {
      display: block;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .utility {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      position: static;

      li {
        flex-grow: 1;
        padding: 20px 0;
        border-right: 1px solid palette(border, reverse);
        flex-basis: 100%;
        border-bottom: 1px solid palette(border, neutrallight);

        &.small {
          flex-basis: 32%;
          border-bottom: 0;
          display: flex;
          justify-content: space-between;
        }

        &.lang {
          padding-left: 10px;

          a {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }

        &.contacts, &.faq {
          padding: 0 10px 0 0;
          margin: 20px 0;
          border-right: 1px solid palette(border, neutrallight);

          a {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }

        &.faq {
          padding: 0 10px 0 20px;

          &:nth-child(3) {
            padding-left: 0;
          }
        }

        a, button {
          font-family: font-family(secondary);
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }

  .lower-bar-row {
    padding-top: 10px;
    padding-bottom: 4px;
  }

  .header-logo {
    align-items: center;
  }

  .header-utility {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    span {
      padding-left: 20px;
    }

    .icon {
      font-size: rem(18);
    }
  }

  .menu-icon {
    @include icon(hamburger, after, false);

    .menu-in & {
      @include icon(close, after, false);
    }
  }
}

.logo {
  width: auto;
  height: 49px;
}

.back-to-root {
  border-right: 1px solid;
  @include icon(home, before, false);

  span {
    display: none;
  }
}

.right-header {
  position: static;
  display: flex;
  flex-direction: column-reverse;

  .col-12.nav-container {
    position: static;
  }

  .cta-button {
    position: absolute;
    bottom: 0;
  }
}

.upper-bar {
  .utility {
    .is-active {
      &.has-submenu {
        padding-bottom: 0;

        .external {
          padding-bottom: 20px;
        }

        .submenu {
          display: inline;
          height: auto;

          a {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid lightgrey;
            padding: 10px 0;

            &:first-child {
              border-top: 1px solid lightgrey
            }

            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }

    .submenu {
      display: none;
    }
  }
}