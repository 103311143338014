.events-carousel {
  .event-infos {
    padding: 30px 8px 8px 8px;
    height: 86px;
    width: 150px;

    h6 {
      font-size: rem(12);
      line-height: 16px;
    }
  }
}