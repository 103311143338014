.events-carousel {
  background-color: bg-color(reverse);

  .carousel-slider {
    padding-bottom: 40px;
  }

  .event-infos {
    position: relative;
    border: solid 1px $neutrallight;
    padding: 30px 20px 8px 8px;
    height: 98px;
    width: 310px;
    cursor: pointer;

    span {
      position: absolute;
      top: -1px;
      left: -1px;
      padding: 6px 8px;
      background: bg-color(neutraldark);
      color: white;
      text-align: center;
      font-family: font-family(secondary);
      font-size: rem(10);
      font-weight: 400;
      line-height: 10px;
      text-transform: uppercase;
    }

    h6 {
      font-size: rem(14);
      line-height: 20px;
      margin: 0;
    }
  }
}