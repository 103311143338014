/* ===GRID SETUP=== */

/**
 * l_pillars is the main grid file, you don't need to change anything here,
 * unless you need to add customized grid rules for specific breakpoints.
 * Otherwise, you can only customize the grid variables 
 * that you can find into the _var_grid.scss abstract file
 */

body {
  &.menu-in {
    overflow: hidden;
  }

  // for phones with notch and navigation bar
  padding: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px) env(safe-area-inset-bottom, 20px) env(safe-area-inset-left, 20px);
}

.contaier-fluid {
  padding: 0 15px;
}

.container {
  max-width: 1440px;
}

.scrollHere {
  position: absolute;
  top: 10px;
}