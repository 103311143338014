.t-unisr-news {
  .title-banner {
    background-color: bg-color(neutral);
    padding-bottom: 20px;

    .container {
      max-width: none;

      .wrapper-banner {
        margin-left: 25%;

        a {
          position: absolute;
          top: 15px;
          left: 0;
          font-size: rem(12);
          font-weight: 700;
          margin-left: calc(7% + 17px);
          color: text-color(primary);

          &:before {
            color: text-color(primary);
            background-color: bg-color(reverse);
          }
        }

        h1 {
          color: text-color(primary);
          margin-right: 40%;
        }

        h3 {
          margin: 10px 50% 10px 0;
        }

        .news-calendar {
          color: text-color(primary);
        }
      }
    }
  }

  .main-content {
    & > .container {
      max-width: none;
      padding: 0;
    }
  }

  .news-content-row {
    justify-content: flex-end;
    background-color: bg-color(reverse);
    margin-top: -30px;

    & > .col-md-5 {
      .category {
        position: absolute;
        top: -11px;
        background: #333;
        color: #fff;
        left: 7px;
        min-width: 100px;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        font-family: font-family(secondary);
        font-size: 1.2rem;
        line-height: 29px;
      }

      padding-top: 30px;
      padding-right: 74px;
      border-right: 1px solid border-color(neutrallight);
    }

    .wrapper-news {
      background-color: bg-color(reverse);

      .widget {
        padding-left: 30px;
        padding-right: 30px;
      }

      .c-media {
        padding-right: 0;
        padding-left: 0;

        .col-12 {
          padding: 0 8px;
        }

        img {
          width: 100%;
        }
      }

      img {
        width: auto;
        height: auto;
      }
    }

    .widget.c-text {
      padding-right: 0;
      padding-left: 0;

      .col-12 {
        padding: 0 8px;
      }
    }

    .news-tags + .news-share {
      margin-top: 0;
    }

    .news-controls, .c-latest-news {
      background-color: bg-color(neutrallight);
    }

    & > .col-md-4 {
      padding-top: 30px;

      .aside {
        padding: 0 95px 0 73px;

        .filter {
          h5 {
            margin-top: 0;
          }
        }
      }
    }
  }

  .category-col {
    .category {
      display: none;
    }
  }
}