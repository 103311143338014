.c-research-banner, .c-text-image {
  background: none;

  .container {
    background-color: bg-color(reverse);
  }

  .description {
    padding: 0 60px 20px 70px;

    h2 {
      display: flex;
      align-items: center;
      margin: 42px 0 24px;
      position: relative;

      &:before {
        content: "";
        width: 50px;
        left: 0;
        position: absolute;
        background-color: bg-color(medicina);
        height: 3px;
      }
    }

    .button-primary {
      font-family: font-family(secondary);
      font-weight: 700;
      font-size: rem(14);
      padding: 6px 54px 6px 0;

      &:after {
        color: text-color(reverse);
        background-color: bg-color(medicina);
        height: 23px;
        width: 23px;
        text-align: center;
        font-size: 8px;
        vertical-align: middle;
        padding: 5px 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .col-md-5 {
    padding-right: 0;
    max-height: 360px;

    .embed-responsive {
      height: 100%;
    }
  }

  & + .c-editorial-cards {
    margin-top: -30px;
  }

  .t-unisr-homepage & {
    .description {
      a {
        font-weight: 600;
        line-height: 20px;
        padding: 10px 20px 10px 8px;
        width: 310px;
      }

      .button-primary:after {
        color: text-color(primary);
        background-color: bg-color(neutrallight);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        font-size: rem(14);
      }
    }
  }
}

.icon-block {
  padding: 30px 0;
  background-color: bg-color(reverse);
  text-align: center;
  @at-root .icon-row {
    background-color: bg-color(reverse);
    padding-top: 30px;
    padding-bottom: 30px;
  }
  &:not(:first-child) {
    border-left: 1px solid border-color(neutrallight);
  }

  .access-icon {
    @include icon(access, before);
  }

  .atom-icon {
    @include icon(atom, before);
  }

  .chain-icon {
    @include icon(chain, before);
  }

  .credits-icon {
    @include icon(credits, before);
  }

  .dna-icon {
    @include icon(dna, before);
  }

  .flask-icon {
    @include icon(flask, before);
  }

  .language-icon {
    @include icon(language, before);
  }

  .meters-icon {
    @include icon(meters, before);
  }

  .researcher-icon {
    @include icon(researcher, before);
  }

  .time-icon {
    @include icon(time, before);
  }

  .icon-title {
    font-family: font-family(secondary);
    font-weight: 400;
    font-size: rem(12);

    &:before {
      display: block;
      font-size: rem(30);
      color: palette(text, medicina);
    }
  }

  span {
    display: block;
  }

  .icon-text {
    font-family: font-family(secondary);
    font-weight: 700;
    font-size: rem(24);
  }
}