.c-static-map {
  //background-color: bg-color(site);
  .textual-info {
    background-color: bg-color(reverse);
  }


  iframe {
    height: 100%;
    min-height: 350px;
  }

  .map-container {
    height: 350px;
    width: 100%;
  }

  .map-block {
    img {
      height: 350px;
      object-fit: cover;
    }
  }


  h3 {
    font-size: rem(34);
    line-height: rem(51px);
  }

  p {
    font-size: rem(16);
    font-family: font-family(secondary);
    line-height: rem(21);
    margin-top: 0;
  }

  .link-direction {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 50px;
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
    max-width: 310px;
    width: 100%;
    justify-content: space-between;
    font-weight: 600;

    i {
      background-color: rgba($medicina, .2);
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $medicina;
    }
  }

}