.t-unisr-error {

  .main-content {
    padding-bottom: 100px;

    .error-wrapper {
      height: 100%;
      padding-top: 200px;

      .error-box {
        padding: 38px 22px 32px 40px;
        max-width: 100%;
        margin-left: 0;
        top: 0;

        h1 {
          &:before {
            width: 30px;
          }
        }
      }
    }
  }
}