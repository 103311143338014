.news-carousel {
  figure {
    width: 150px;

    .news-image  {
      height: 80px;
    }

    figcaption {
      height: 207px;
      padding: 0 4px 56px 10px;

      small {
        padding-top: 40px;
        padding-bottom: 10px;
      }

      h5 {
        line-height: 20px;
        padding-top: 0;
      }
    }
  }
}