.t-unisr-news-listing {
  .main-content {
    .filter.row {
      height: 102px;
    }
    .listing-news {
      margin-bottom: 20px;
    }
  }
}

.c-grid-news {
  &.widget {
    margin-bottom: 30px;
  }
  .news-tags {
    .container {
      padding: 0;
    }
  }
  .c-latest-news {
    padding-bottom: 170px;
  }
}