.c-banner-faculty {
  &.widget {
    margin-bottom: 30px;
  }

  .facultycard {
    a {
      font-size: rem(14);
    }
  }
}