.c-text {
  padding: 40px 60px 40px;
  background-color: bg-color(reverse);

  .col-12 {
    position: static;
  }

  h2,
  h3,
  h4 {
    margin-top: 0;
  }

  h2, h3, h4 {
    margin-top: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &:before {
      content: "";
      width: 50px;
      left: 15px;
      position: absolute;
      height: 2px;
      background-color: text-color(neutrallight);
    }

    &.hide-line {
      &:before {
        content: none;
      }
    }
  }

  h3 {
    font-size: rem(23);
  }

  h4 {
    font-size: rem(16);
  }

  table, td, th {
    border: 1px solid rgba(0, 0, 0, .1);
    border-spacing: 0;
    border-collapse: collapse;
  }

  table {
    td {
      padding: 6px 10px;
    }
  }

  .cta-wrapper {
    display: flex;

    .button-primary {
      background-color: bg-color(neutrallight);
      color: text-color(primary);
      margin-bottom: 20px;

      &:hover {
        background-color: bg-color(primary);
        color: text-color(reverse);
      }
    }

    &.right {
      justify-content: flex-end;
    }

    &.center {
      justify-content: center;
    }
  }

  ul {
    list-style: none;
    padding-left: 22px;

    li {
      margin-bottom: 25px;

      &:before {
        content: "\25A0";
        font-size: 20px;
        display: inline-block;
        width: 22px;
        margin-left: -22px;
      }
    }
  }

  ol {
    counter-reset: item;
    list-style-type: none;
    padding: 0;

    li {
      &:before {
        content: counter(item) " ";
        counter-increment: item;
        font-weight: 700;
        margin-right: 10px;
        color: text-color(neutralmedium);

        .psicologia &, .filosofia &, .medicina & {
          color: text-color(neutralmedium);
        }
      }
    }
  }

  .readmore-text-active {
    //overflow: hidden;

    & > * {
      display: none;
      overflow: hidden;

      li {
        display: none;
      }
    }
  }

  .button-text.cv-read-more {
    display: none;
    border-top: 1px solid;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    margin-top: 20px;
    background: none;
    position: relative;
    padding: 26px 0 30px;
    font-family: font-family(secondary);
    font-weight: 700;
    font-size: rem(12);
    color: text-color(neutralmedium);
    @include icon(chevron, before, false);

    &:before {
      color: text-color(reverse);
      background-color: bg-color(neutralmedium);
      transform: rotate(90deg);
      display: block;
      width: 23px;
      line-height: 23px;
      font-size: rem(10);
      margin: -13px auto 0;
      border: none;
      top: 0;
      right: 0;
      left: 0;
      position: absolute;
    }

    &.visible {
      display: block;
      width: 100%;
      text-align: center;
      padding-left: 0;
    }
  }

  & + .c-text {
    margin-top: -134px;
  }

  .handler-accordion {
    &.title {
      font-weight: 700;
      padding: 0;
      position: static;

      &:after {
        display: none;
      }
    }
  }

  a {
    text-decoration: none;
  }

  strong {
    font-weight: 700;
  }

  .document-download {
    &.document-expired {
      background-color: #fee605 !important;

      a {
        background-color: #fee605 !important;
      }
    }

    .document-caption {
      font-family: font-family(secondary);
      position: relative;
      padding-left: 54px;

      &:before {
        content: "";
        width: 46px;
        position: absolute;
        height: 1px;
        background-color: bg-color(neutrallight);
        bottom: 0;
        left: -9px;
      }
    }
  }

  .button-download {
    padding-left: 54px;

    &:after {
      right: 3px;
    }
  }

  .highlight {
    color: palette(text, neutraldark);
    position: relative;

    font-weight: 700;
    margin: 0;
    margin-bottom: 20px;
    padding: 20px;
    background-color: bg-color(neutrallight);
    border-left: 3px solid border-color(filosofia);

    &:before {
      content: '';
      position: absolute;
      left: 1%;
      //width: 4px;
      //background-color: bg-color(primary);
      top: -5px;
      bottom: -5px;
    }

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.readmore-text-active {
  //overflow: hidden;

  & > * {
    display: none;
    overflow: hidden;

    li {
      display: none;
    }
  }
}

.button-text.cv-read-more {
  display: none;
  border-top: 1px solid;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  margin-top: 20px;
  background: none;
  position: relative;
  padding: 26px 0 30px;
  font-family: font-family(secondary);
  font-weight: 700;
  font-size: rem(12);
  color: text-color(neutralmedium);
  @include icon(chevron, before, false);

  &:before {
    color: text-color(reverse);
    background-color: bg-color(neutralmedium);
    transform: rotate(90deg);
    display: block;
    width: 23px;
    line-height: 23px;
    font-size: rem(10);
    margin: -13px auto 0;
    border: none;
    top: 0;
    right: 0;
    left: 0;
    position: absolute;
  }

  &.visible {
    display: block;
    width: 100%;
    text-align: center;
    padding-left: 0;
  }
}