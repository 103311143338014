.c-static-map {
  overflow: hidden;
  padding: 0;

  .map-container {
    position: absolute;
    width: 100%;
  }

  .map-block {
    padding: 0;
  }

  .icon {
    flex: 1 0 50%;
    max-width: 50%;
  }

  .textual-info {
    padding: 15px;
  }

  .link-direction {
    position: static;
    margin-top: 30px;
    margin-bottom: 20px;
  }

}
