.c-sevices-box {
  margin-bottom: 25px;
  &:hover a {
    text-decoration: underline;
  }
}

.c-sevices-box__content {
  height:100%;
  padding: 30px;
  background-color: bg-color(reverse);
}

.c-sevices-box-icon {
  border-radius: 50%;
  height: 160px;
  width: 160px;
  border: 1px solid border-color(neutrallight);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.c-sevices-box-icon__image {
  height: 80px;
  width: 80px;
}

.c-sevices-box__title {
  font-weight: 700;
  font-size: 19px;
  line-height: 24px;

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}