.c-banner-faculty {
  position: relative;
  margin-top: -15px;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  .container {
    padding: 0;
  }

  .row {
    justify-content: space-around;
    padding: 0 15px;
  }

  .facultycard {
    width: 105px;
    height: 113px;
    padding: 0;

    .button-primary {
      flex-direction: column;
      padding: 0 0 8px;

      &:after {
        height: 23px;
        width: 23px;
        line-height: 23px;
        font-size: rem(8);
        margin: 0;
      }

      figure {
        padding-top: 6px;
        padding-bottom: 10px;

        picture {
          justify-content: center;

          & > img {
            height: auto;
          }
        }
      }
    }
  }

}