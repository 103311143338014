.c-albo {
  padding: 22px 0;
  margin-right: -15px;
  margin-bottom: 30px;
  margin-left: -15px;

  .document-download {
    .document-caption {
      &:before {
        content: none;
      }
    }
  }
}