.c-grid-news {
  &.widget {
    margin-bottom: 40px;
  }
  .newscard {
    &:last-child {
      display: none;
    }
  }
}

.t-unisr-news-listing {
  .main-content {
    .filter.row {
      .col-6 {
        padding: 0;
        &:first-child {
          border-right: 1px solid border-color(neutrallight);
        }
        .handler-accordion {
          border: none;
          padding-right: 10px;
          padding-left: 10px;
          &:after {
            right: 6px;
          }
        }
        &:first-child {
          .target-accordion {
            right: -1px;
            left: 0;
            border-left-style: none;
          }
        }
        &:last-child {
          .target-accordion {
            left: -1px;
            right: 0;
            border-right-style: none;
          }
        }
      }
    }
    .listing-news {
      padding-top: 67px;
      .newscard {
        &:nth-child(even) {
          padding-right: 10px;
          padding-left: 5px;
        }
        &:nth-child(odd) {
          padding-right: 5px;
          padding-left: 10px;
        }
      }
    }
  }
}