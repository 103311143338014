.c-accordion {
  width: 100%;

  .col {
    padding: 0;
  }

  .wrapper-component {
    margin-bottom: 10px;
    background-color: bg-color(neutralverylight);
  }

  .handler-accordion {
    &:after {
      right: 10px;
    }

    &:hover, &.not-collapsed {
      background-color: bg-color(neutralmedium);
      color: palette(text, reverse);

      &:after {
        color: text-color(primary);
      }

      .filosofia & {
        background-color: bg-color(filosofia);
        color: palette(text, reverse);

        &:after {
          background-color: bg-color(filosofiadark);
          color: text-color(reverse);
        }
      }

      .medicina & {
        background-color: bg-color(medicina);
        color: palette(text, reverse);

        &:after {
          background-color: bg-color(medicinadark);
          color: text-color(reverse);
        }
      }

      .psicologia & {
        background-color: bg-color(psicologia);
        color: palette(text, reverse);

        &:after {
          background-color: bg-color(psicologiadark);
          color: text-color(reverse);
        }
      }
    }
  }

  .target-accordion {
    padding: 30px;
    background-color: bg-color(reverse);

    p {
      a {
        color: text-color(medicina) !important;
        text-decoration: none;

        &:hover {
          color: text-color(medicinadark) !important;
        }
      }
    }

    figure {
      margin-bottom: 20px;
    }

    &.bg-light {
      background-color: bg-color(neutralverylight);
    }
  }

  img {
    display: block;
  }
}

.c-accordion {
  .handler-accordion {
    font-family: font-family(secondary);
    //& > h2, h3, h4, h5, h6 {
    position: relative;
    font-size: rem(19);
    font-weight: 700;
    min-height: 70px;

    padding: 30px 30% 30px 30px;
    margin: 0;

    &:before {
      content: none;
    }

    &:after {
      display: inline-block;
      right: 30px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      background-color: bg-color(neutrallight);
      text-align: center;
    }

  }
}
