header.main {
  .upper-bar {

    a, button {
      padding-top: 10px;
      padding-bottom: 10px;
      font-weight: 300;
      font-size: rem(12);
    }
  }

  .lower-bar {
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .lower-bar-row {
    justify-content: space-between;
    height: 85px;
  }

  .header-logo {
    align-items: flex-start;
  }

  .utility {
    list-style: none;
    margin: 0;
    text-align: right;

    li {
      display: inline-block;
    }

    a, button {
      padding-left: 20px;
      padding-right: 30px;

    }

    .submenu {
      box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);

      a {
        padding-right: 0;
        padding-left: 0;
        margin-right: 20px;
        margin-left: 20px;

        &:not(:last-child) {
          border-bottom: 1px solid palette(border, neutrallight);
        }
      }
    }
  }

  .logo {

    html.ie & {
      height: 100%;
      max-width: 100%;
    }
  }

  .back-to-root {
    @include icon(chevron, before, false);

    &:before {
      transform: rotate(180deg);
      border: 1px solid;
      border-radius: 50%;
      width: 23px;
      text-align: center;
      line-height: 20px;
      font-size: rem(10);
      margin-right: 6px;
    }
  }
}

.upper-bar {
  nav.utils {
    & > ul {
      & > li {
        & > a, button {
          padding-bottom: 0;
        }
      }
    }
  }
}