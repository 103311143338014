.t-unisr-research {
  .research-introduction {
    margin: 10px 0 28px;
    & > h2 {
      margin: 0;
    }
  }

  .c-grid-news {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid border-color(neutralmedium);
    &.widget {
      margin-bottom: 35px;
    }
    .container {
      padding: 0;
    }
    & > h2 {
      flex-grow: 1;
      margin-top: 0;
      margin-bottom: 16px;
    }
    & > a {
      display: flex;
      align-items: center;
      font-size: rem(14);
      margin-bottom: 26px;
      &.with-arrow {
        font-weight: 700;
        padding-right: 0;
        padding-left: 0;
        &:after {
          background-color: bg-color(reverse);
          color: text-color(primary);
          height: 23px;
          width: 23px;
          text-align: center;
          font-size: 8px;
          vertical-align: middle;
          line-height: 23px;
          padding-left: 2px;
          margin-left: 30px;
        }
      }
    }
    .newscard {
      padding-bottom: 40px;
    }
  }
}