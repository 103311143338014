.c-story-card-component {
  .c-story-card {
    background-color: bg-color(reverse);
    margin-bottom: 20px;

    .wrap-img {
      img {
        width: 100%;
      }
    }

    span {
      display: block;
      margin-bottom: 5px;

      &.author,
      &.role {
        font-size: rem(14);
      }

      &.hashtag {
        font-size: rem(12);
        text-transform: uppercase;
        letter-spacing: 2px;
        color: text-color(secondary);
      }

      &.role {
        color: text-color(secondary);
      }
    }

    .button-primary {
      display: inline-block;
      margin-top: 15px;
    }

    blockquote {
      @include icon('quote-open', before);
      margin: 0;
      position: relative;
      display: inline-block;

      &:after {
        transform: rotate(180deg);
        bottom: 5px;
        right: -12px;
      }

      &:before {
        left: -12px;
        top: -5px;
      }

      &:before,
      &:after {
        font-size: rem(50);
        position: absolute;
      }
    }

    h3 {
      margin: 0;
      padding: 12px 20px;
      color: text-color(neutrallight);
      font-size: rem(21);
      font-family: font-family(secondary);
      font-weight: 500;
      font-style: italic;
    }

    figure {
      display: flex;
      align-items: center;

      .wrap-img {
        height: 0;
        overflow: hidden;
        padding-top: 31.05%;
        position: relative;

        > * {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      .wrap-text {
        height: 100%;
        position: relative;
        padding: 28px 30px 20px 120px;
        text-align: left;

        blockquote {
          padding-left: 0;
          position: static;

          &:before {
            top: 0;
            left: 46px;
          }

          h3 {
            padding-left: 0;
            color: text-color(neutralmedium);
          }
        }

        figcaption {
          font-family: font-family(secondary);
          font-size: rem(12);
          font-weight: 500;
          font-style: italic;
          color: text-color(neutralmedium);
          margin-top: 10px;
        }
      }
    }
  }

  .carousel-wrapper {
    padding-bottom: 18px;

    .tns-nav {
      width: 100%;
      bottom: -25px;
      text-align: center;
    }
  }
}