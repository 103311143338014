.c-story-card-component {
  .c-story-card {

    &.right {
      figure {
        flex-direction: row-reverse;
      }
      .wrap-img {
        padding-right: 0;
        padding-left: 20px;
      }
    }

    .wrap-img {
      width: 31.05%;
      padding-right: 20px;
    }

    .wrap-text {
      width: 66.667%;
      text-align: center;
      padding: 0 30px;

      .hashtag {
        padding-top: 5px;
      }

      .button-primary {
        margin-bottom: 5px;
      }
    }

    + .c-story-card {
      margin-top: 30px;
    }
  }
}