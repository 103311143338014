.t-unisr-section-home {
  &:not(.not-overlay) {

    .main-content {
      .tabs-right-col.col-12 {
        margin-top: 30px;
      }

      .widget.c-info-boxes {
        &:first-child {
          margin-top: -62px;
        }
      }
    }
  }
}