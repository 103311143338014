.c-carousel {
  overflow: hidden;

  .slide {
    display: flex;
    justify-content: flex-end;
  }

  .t-unisr-homepage & {
    padding-top: 0;
    padding-left: 1px;

    .container {
      padding: 0;

      .row {
        margin-left: 0;
        margin-right: 0;
      }

      .col-12 {
        padding: 0;
      }
    }
  }

  body:not(.t-unisr-homepage) & {
    &.widget {
      padding-bottom: 40px;
    }
  }

  .carousel-nav {
    & > p {
      font-weight: 700;
    }

    & > p:not(.tns-nav-active) {
      position: absolute;

      display: none;

    }
  }

  .real-carousel {
    & > div {
      display: inline-block;
    }
  }

  .slidecard {
    & > a {
      display: flex;
      align-items: center;
    }
    .button-primary {
      &:after {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        font-size: rem(16);
      }
    }
  }

}