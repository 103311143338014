.news-share {
  border-top: 3px solid $neutralverylight;
  margin-top: 50px;
  ul {
    margin: 24px 0;
    padding: 0;
    list-style: none;
    //text-align: center;

    li {
      display: inline-block;
      margin-right: 8px;
      margin-bottom: 8px;
      &:not(:last-child) {
        margin-right: 30px;
      }
      a {
        border-radius: 3px;
        width: 35px;
        height: 35px;
        font-size: 1.2rem;
        line-height: 34px;
        text-align: center;
        color: palette(text, neutraldark);
        //background-color: bg-color(neutrallight);

        padding: 10px;
        border: 1px solid;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        font-size: 1.4rem;
        text-align: center;
        vertical-align: middle;
        line-height: 18px;



        &:hover {
          color: palette(text, reverse);
          background-color: bg-color(primary);
        }
      }
    }
  }
}