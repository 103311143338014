.cookie-policy-container {
  height: 200px;

  &.accepted {
    bottom: -200px;
  }

  .cookie-policy-text {
    p {
      margin-bottom: 0;
    }
  }

  .button-accept-cookie {
    width: 60%;
    text-align: center;
  }
}