.slide {
  .slidecard {
    align-self: center;
    padding: 40px 70px;
    margin-right: -33%;

    .button-primary {
      margin-top: 35px;
    }
  }
}

.hero-carousel {
  max-height: 500px;
}