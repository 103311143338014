.t-unisr-course, .t-unisr-tabs-child, .t-unisr-internal {
  //margin-bottom: 46px; //NOTE this is kinda static.. should find a rule to put margin once the apply/download brochure buttons are sticked to the bottom
  .course-highlight {
    margin-top: 0;
    margin-bottom: 5px;

    .icon-row {
      padding: 25px;

      .icon-block {
        padding-top: 15px;
        padding-bottom: 25px;

        &:first-child {
          padding-left: 15px;
          margin-right: 24px;
          margin-bottom: -1px;
          border-bottom: 1px solid border-color(neutrallight);
          max-width: calc(50% - 25px);
          z-index: 1;
        }

        &:nth-child(2) {
          border-left: 1px solid border-color(neutrallight);
          margin-bottom: 15px;
          padding-right: 0;
          padding-bottom: 0;
          padding-left: 25px;
          padding-bottom: 18px;
        }

        &:nth-child(3) {
          margin-top: 15px;
          border-right: 1px solid border-color(neutrallight);
          padding: 14px 25px 16px 0;
        }

        &:last-child {
          margin-left: 25px;
          max-width: calc(50% - 25px);
          border-top: 1px solid border-color(neutrallight);
          padding: 28px 0 16px;
        }

        .icon-text {
          font-size: rem(14);
        }
      }
    }

    .brochure-col {
      display: none;
    }
  }

  .cta-buttons {
    display: flex;
    margin: 5px -15px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: font-family(secondary);
      font-weight: 700;
      font-size: rem(12);
      flex-grow: 1;
      min-height: 40px;
      background-color: bg-color(reverse);
      text-decoration: none;
      text-align: center;
      width: 50%;
      border: 3px solid;

      &:first-child {
        color: text-color(reverse);
      }
    }
  }

  .scroll-to-top {
    bottom: 60px;
  }

  .tabs-right-col {
    //margin-top: 40px;
  }
}