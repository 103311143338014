.title-banner {
  display: flex;
  align-items: center;
  min-height: 200px;

  &.bg-img {
    padding-top: 0;
    padding-bottom: 0;

    &:not(.hero):not(.bg-hero):not(.hero-image) {
      h1 {
        font-size: rem(40);
        margin: 0;
      }
    }
  }

  &.hero {
    background-size: cover;
    background-repeat: no-repeat;
    height: 282px;
    //padding-top: 60px;
  }

  &.bg-hero {
    height: 282px;
  }

  .hero-image {
    height: 282px;
  }

  .t-unisr-organizzazione-governance &, .t-unisr-research & {
    min-height: 227px;

    h1 {
      font-size: rem(32);
    }
  }

  .t-unisr-tabs &, .t-unisr-course-tabs &, .t-unisr-course &, .t-unisr-internal.not-overlay.with-course-box & {
    h1 {
      font-size: rem(32);
    }
  }

  .t-unisr-search-results & {
    min-height: 158px;
    height: 158px;

    h1 {
      margin: 0;
      font-size: rem(32);
      padding: 0 12px;
    }
  }
}