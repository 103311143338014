.filter {
  &.docenti {
    .handler-smalldown {
      @include icon(chevron, after, false);
      width: 100%;
      text-align: left;
      background-color: bg-color(reverse);
      line-height: 1.5;
      padding: 8px 14px;
      text-transform: none;
      font-size: rem(14);

      &:after {
        float: right;
        transform: rotate(90deg) scaleY(-1);
      }

      &.not-collapsed {
        border-bottom: 0;

        &:after {
          transform: rotate(-90deg);
        }
      }
    }

    [data-collapse-target] {
      display: none;
      background-color: bg-color(reverse);
      padding: 15px;

      &.not-collapsed {
        border-top: 0;
      }
    }

    button {
      &:not(.all):not(.handler-smalldown) {
        padding: 10px 8px;
        text-align: left;
        margin-right: 10px;
      }
    }

    button {
      &.all:not(.handler-smalldown):not(.filosofia):not(.medicina):not(.psicologia):not(.default),
      &.hide-smalldown:not(.handler-smalldown):not(.filosofia):not(.medicina):not(.psicologia):not(.default) {
        .global & {
          padding: 10px 8px;
          margin-bottom: 16px;

          &.active {
            color: text-color(reverse);
            margin-bottom: 16px;
            background-color: bg-color(brand);
          }
        }
      }
    }
  }
}

