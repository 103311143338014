/* ===PALETTE SETUP=== */

/**
 * Replace the following colors with the one you actually need for your project.
 * These ones below are used just for sample scope and were taken from http://bada55.io/
 */

$black: #000000;
$white: #ffffff;
$transparent: transparent;

$alcool: #a1c001;
$booobs: #b000b5;
$badass: #bada55;
$coffee: #c0ffee;
$decode: #dec0de;
$erotic: #e2071c;
$fables: #fab135;
$loaded: #10aded;
$rebecca: #663399;
$tunno: #5000c4;

$primary:            #333333;
$secondary:          #430b14;
$filosofia:          #cb333b;
$filosofialight:     #eaadb0;
$filosofiadark:      #97262C;
$medicina:           #009cde;
$medicinalight:      #99d7f2;
$medicinadark:       #006894;
$psicologia:         #ffc72c;
$psicologialight:    #ffe9aa;
$psicologiadark:     #D69D00;
$orientamento:       #ef7b10;
$postlauream:        #cdc3e0;

$neutral:            #f0f0f0;
$neutralmedium:      #54585A;
$neutraldark:        #333333;
$neutrallight:       #e6e6e6;
$neutralverylight:   #fafafa;
$neutralverydark:    #252525;
$neutralmidlight:    #b5b5b5;

$textinput:          #999;


/**
 * in order to use one the colors included in the palette map,
 * you can simply use the functions text-color(key), bg-color(key), border-color(key)
 * ie: text-color(primary)
 */

$palette: (
  text: (
    primary: $neutraldark,
    secondary: $secondary,
    tertiary: $coffee,
    reverse: $white,
    brand: $primary,
    success: $badass,
    warning: $fables,
    error: $erotic,
    neutral: $neutral,
    neutraldark: $neutraldark,
    neutrallight: $neutrallight,
    neutralmedium: $neutralmedium,
    neutralmidlight: $neutralmidlight,
    filosofiadark: $filosofiadark,
    filosofia: $filosofia,
    filosofialight: $filosofialight,
    medicina: $medicina,
    medicinalight: $medicinalight,
    medicinadark: $medicinadark,
    psicologia: $psicologia,
    psicologialight: $psicologialight,
    psicologiadark: $psicologiadark,
    orientamento: $orientamento,
    postlauream: $postlauream,
    neutralverylight: $neutralverylight,
    neutralverydark: $neutralverydark,
    transparent: $transparent,
    filtersdocenti: $textinput
  ),
  background: (
    primary: $primary,
    secondary: $secondary,
    tertiary: $coffee,
    reverse: $white,
    brand: $primary,
    success: $badass,
    warning: $fables,
    error: $erotic,
    neutral: $neutral,
    neutraldark: $neutraldark,
    neutrallight: $neutrallight,
    neutralmedium: $neutralmedium,
    neutralmidlight: $neutralmidlight,
    filosofia: $filosofia,
    filosofialight: $filosofialight,
    filosofiadark: $filosofiadark,
    medicina: $medicina,
    medicinalight: $medicinalight,
    medicinadark: $medicinadark,
    psicologia: $psicologia,
    psicologialight: $psicologialight,
    psicologiadark: $psicologiadark,
    orientamento: $orientamento,
    postlauream: $postlauream,
    neutralverylight: $neutralverylight,
    neutralverydark: $neutralverydark,
    transparent: $transparent,
    filtersdocenti: $textinput
  ),
  border: (
    primary: $black,
    secondary: $secondary,
    tertiary: $coffee,
    reverse: $white,
    brand: $primary,
    success: $badass,
    warning: $fables,
    error: $erotic,
    neutral: $neutral,
    neutraldark: $neutraldark,
    neutrallight: $neutrallight,
    neutralmedium: $neutralmedium,
    neutralmidlight: $neutralmidlight,
    filosofia: $filosofia,
    medicina: $medicina,
    medicinalight: $medicinalight,
    filosofialight: $filosofialight,
    medicinadark: $medicinadark,
    psicologia: $psicologia,
    psicologialight: $psicologialight,
    psicologiadark: $psicologiadark,
    orientamento: $orientamento,
    postlauream: $postlauream,
    neutralverylight: $neutralverylight,
    neutralverydark: $neutralverydark,
    transparent: $transparent,
    filtersdocenti: $textinput
  )
);

/* ===BUTTONS COLORS SETUP=== */

/**
 * The $buttons map is used to style buttons
 * Please consider that the key used is the one used for classes name as well
 * ie: .button-primary, .button-secondary
 */

$buttons: (
  primary: (
    color: $white,
    background-color: $primary,
    border-color: $primary,
    hover-color: $primary,
    hover-background-color: $white,
    hover-border-color: $primary,
    active-color: $primary,
    active-background-color: $white,
    active-border-color: $primary
  ),
  secondary: (
    color: $neutraldark,
    background-color: $white,
    border-color: $neutrallight,
    hover-color: $white,
    hover-background-color: $neutrallight,
    hover-border-color: $neutrallight,
    active-color: $white,
    active-background-color: $white,
    active-border-color: $white
  ),
  filosofia: (
    color: $white,
    background-color: $filosofia,
    border-color: $filosofia,
    hover-color: $filosofia,
    hover-background-color: $white,
    hover-border-color: $filosofia,
    active-color: $filosofia,
    active-background-color: $white,
    active-border-color: $filosofia
  ),
  medicina: (
    color: $medicinadark,
    background-color: $medicinalight,
    border-color: $medicinalight,
    hover-color: $medicinadark,
    hover-background-color: $white,
    hover-border-color: $medicinadark,
    active-color: $medicinadark,
    active-background-color: $white,
    active-border-color: $medicinadark
  ),
  psicologia: (
    color: $psicologiadark,
    background-color: $psicologia,
    border-color: $psicologia,
    hover-color: $psicologiadark,
    hover-background-color: $white,
    hover-border-color: $psicologiadark,
    active-color: $psicologiadark,
    active-background-color: $white,
    active-border-color: $psicologiadark
  ),
  chat: (
    color: $white,
    background-color: $filosofia,
    border-color: $filosofia,
    hover-color: $filosofia,
    hover-background-color: lighten( $filosofia, 5% ),
    hover-border-color: $filosofia,
    active-color: $white,
    active-background-color: $white,
    active-border-color: $filosofia
  ),
  meeting: (
    color: $white,
    background-color: $medicina,
    border-color: $medicina,
    hover-color: $white,
    hover-background-color: lighten($medicina, 5% ),
    hover-border-color: $medicinadark,
    active-color: $medicina,
    active-background-color: $white,
    active-border-color: $medicinadark
  ),
);

$tabs: (
  default: (
    color: $neutraldark,
    background-color: $white,
    hover-color: $neutraldark,
    hover-background-color: $white,
    active-background-color: $primary,
    active-color: $white,
    notcollapsed-background-color: $secondary
  ),
  filosofia: (
    color: $white,
    background-color: $filosofia,
    active-background-color: $secondary,
    active-color: $white
  ),
  medicina: (
    color: $medicinadark,
    background-color: $medicinalight,
    active-background-color: $medicinadark,
    active-color: $white
  ),
  psicologia: (
    color: $psicologiadark,
    background-color: $psicologia,
    active-background-color: $psicologiadark,
    active-color: $white
  )
);
