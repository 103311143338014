.c-text-image {
  .description {
    padding-right: 65px;

    h2 {
      &:before {
        background-color: bg-color(neutrallight);
      }
    }

    p {
      margin-bottom: 0;
    }

    .button-primary {
      margin-top: 4px;
      color: text-color(neutraldark);

      &:after {
        color: text-color(primary);
        background-color: bg-color(neutrallight);
      }
    }
  }

  .picture-col {
    padding-right: 0;

    img {
      vertical-align: middle;
    }
  }

  .c-numbers {
    padding: 10px 0 32px;
    margin: 0;
    text-align: left;

    .c-numbers-item-label {
      padding: 0;
      color: text-color(neutraldark);
    }

    .c-numbers-item {
      color: text-color(filosofia);
    }
  }

  .caption-col {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0;
  }
}