body {
  &.t-unisr-news-listing,
  &.t-unisr-docenti-listing,
  &.t-unisr-docente {
    .main-content {
      padding: 15px 0;
    }
  }

  &.t-unisr-news {
    .main-content {
      padding: 15px 0 0;
    }
  }

  .main-content {
    padding-bottom: 40px;
  }
  &.menu-in {
    position: fixed;
  }
}
.mobileListener {
  height: 1px;
  width: 1px;
}
.fixed {
  position: fixed;
  top: 0;
  z-index: 6;
  & + ul {
    position: fixed;
    top: 57px;
    z-index: 6;
    & + .marginer {
      height: 57px;
    }
  }
}