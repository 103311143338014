.c-text-image {
  .picture-col {
    padding: 0;
  }

  .c-numbers {
    padding-bottom: 0;

    .row {
      margin: 0;

      .col-4 {
        &:nth-child(2) {
          padding-right: 5px;
        }
      }
    }
  }

  .caption-col {
    text-align: center;
    padding-right: 25px;
    padding-left: 25px;
  }
}