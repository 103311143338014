.t-unisr-target-detail {
  &:not(.not-overlay) {

    .main-content {
      .widget.c-info-boxes {
        &:first-child {
          margin-top: -32px;
        }
      }
    }
  }
}