.c-media-card {
  .c-media-card-item {
    position: relative;
    margin-bottom: 30px;
    z-index: 1;
  }
  .c-media-card-label {
    z-index: $carouselcontrols;
    color: text-color(reverse);
    //text-transform: uppercase;
    font-weight: normal;
    text-align: center;
    font-size: rem(26);
    text-align: center;
    position: absolute;
    padding: 0 15px;
    width: 95%;
    top: 40%;
  }
  .c-media-card-cta {
    border-radius: 0;
    padding: 10px 12px;
    z-index: $carouselcontrols;
    background-color: bg-color(neutrallight);
    color: text-color(neutralverydark);
    text-decoration: none;
    display: none;
    &:hover{
      background-color: bg-color(reverse);
      color: text-color(primary);
    }
  }
  &.has-shadow {
    .c-media-card-item {
      &:before,
      &:after {
        left: 18px;
        right: 50%;
        bottom: 8px;
        z-index: -1;
        height: 30%;
        content: '';
        position: absolute;
        transform-origin: 0 0;
        transform: skewY(-6deg);
        box-shadow: 0 11px 15px rgba(0, 0, 0, .5);
      }
      &:after {
        left: 50%;
        right: 18px;
        transform: skewY(6deg);
        transform-origin: 100% 0;
      }
    }  
  }
  &.has-overlay {
    .c-media-card-item {
      height: 257px;
      .bg-container {
        width: 100%;
        height: 100%;
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      .c-media-card-overlay {
        background-color: bg-color(primary);
        transition: height .3s;
        position: absolute;
        overflow-y: hidden;
        width: 100%;
        height: 0;
        bottom: 0;
        left: 0;
      }
      .c-media-card-label{
        position: relative;
        top: 0;
      }
      &:hover {
        .c-media-card-overlay {
          height: 100%;
        }
        .c-media-card-cta {
          display: block;
        }
      }
    }
  }
}