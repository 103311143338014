.tns-controls {
  button {
    .t-unisr-homepage & {
      padding: 15px 25px;
      background-color: bg-color(reverse);

      &:first-of-type {
        padding-right: 81px;
      }
    }
  }

}