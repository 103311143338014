nav.main, nav.utils {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  position: static;
  padding-left: 0;
  height: 100%;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
    }
  }

  .has-submenu {
    > a, button {

      &.is-active {
        position: relative;
      }
    }
  }

  .submenu {
    display: none;
    position: absolute;
    width: 240px;
    left: 0;
    z-index: $navigation-zindex;
    background-color: bg-color(reverse);
    font-size: rem(16);
    border-top: 2px solid palette(border, neutrallight);

    .container {
      max-width: 1500px;
      padding: 0 30px;
      position: relative;
      background-color: bg-color(reverse);
    }

    &.megamenu {
      width: 100%;
      right: 0;
      left: 0;
      border-bottom: 2px solid border-color(neutral);

      &:before {
        content: "";
        width: 50%;
        left: 0;
        top: 82px;
        background-color: bg-color(neutralverylight);
        height: 3px;
        position: absolute;
      }
    }

    &.is-active {
      display: block;
    }

    li {
      display: block;
      padding: 8px 0;

      &:hover {
        background-color: bg-color(neutralverylight);
      }
    }

    .container {
      display: flex;
    }

    a {
      &:not(.handler):not(.card-column):hover {
        background-color: bg-color(neutralverylight);
      }

      &.handler:hover {
        cursor: default;
      }

      &.active {
        text-decoration: underline;
      }
    }

    .block-title {
      font-weight: 700;
    }
  }

  .column {
    width: 100%;
    padding: 60px 10px 10px 10px;

    &:first-child {
      border-right: 2px solid bg-color(neutral);
    }

    &:not(:first-child) {
      padding-top: 68px;
    }

    &:nth-child(2) {
      margin-left: 80px;
    }

    .block {
      + .block {
        margin-top: 15px;
      }
    }

    .megamenu & {
      max-width: 33%;
    }
  }

  > ul {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;

    > li {
      height: 100%;

      &:not(.megamenu) {
        position: relative;
      }

      &.cta-button {
        a {
          width: 217px;

          &:first-child {
            margin-right: 20px;
          }
        }
      }

      > a, button {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 10px 8px;
        font-size: rem(14);

        &.active,
        &:hover {
          position: relative;
        }
      }

      &:last-of-type {
        a {
          padding-right: 0;
        }
      }
    }
  }

  .goback {
    display: none;
  }

  .right {
    .submenu:not(.megamenu) {
      left: auto;
      right: 0;
    }
  }
}

nav.main {
  .submenu {
    &.is-active {
      margin-top: 20px;
    }
  }
}

nav.utils {
  flex-grow: 1;
  padding: 0 15px;

  & > ul {
    justify-content: flex-end;
  }

  li {
    a, button {
      &:after {
        margin-left: 10px;
      }
    }

    &.has-submenu {
      &.is-active {
        & > button {
          color: text-color(medicina);

          &:after {
            transform: rotate(90deg);
            color: text-color(reverse);
            background-color: bg-color(medicina);
          }
        }

        .submenu {
          border-top: 2px solid palette(border, medicina);
        }
      }
    }
  }

  .submenu {
    left: auto;
    margin-left: -12px;
  }
}