.t-unisr-contacts {

  .main-content {
    .widget {
      margin-bottom: 20px;
      &:first-child {
        margin-top: -70px;
      }
    }
  }

  .c-banner-contacts {
    p {
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 28px;
    }
  }


  .facultycard {
    margin: 0 0 20px;
    background: none;

    > div {
      flex-direction: column;
      padding: 25px 60px 30px 60px;
    }

    h2 {
      font-size: rem(34);
      text-align: center;
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: 0;
      min-width: 340px;
      min-height: 50px;
      font-size: 14px;
      font-weight: 500;
      &::after {
        content: none;
      }
    }
  }

  .facultycard-image {
    width: auto;
    height: auto;
    margin-top: 24px;
    border: none;
  }

}
