.events-calendar-month {
  .calendar-month-text {
    padding: 0;
    margin: 9px 0;
    font-size: rem(16);
  }
}

.events-calendar-nav {
  height: 36px;
  &[data-calendar-direction="prev"] {
    padding-bottom: 24px;
  }
  &[data-calendar-direction="next"] {
    padding-top: 12px;
  }
}