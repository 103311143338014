.c-title {
  .boxed {
    margin: 0;
    background-color: bg-color(neutrallight);
    padding: 8px 10px;
    font-size: 16px;
    font-weight: 500;
  }

  .colorfaculty {
    color: text-color(brand);
    
    .medicina & {
      color: text-color(medicinadark);
    }
    .filosofia & {
      color: text-color(filosofia);
    }
    .psicologia & {
      color: text-color(psicologiadark);
    }
  }

  h3,
  h2 {
    position: relative;
    & + p {
      margin-top: -15px;
    }
  } 

  h2 {
    font-size: rem(24);
    font-weight: 500;
  }

  h2,
  h3 {
    &.preline {
      margin-top: 40px;
  
      &:before {
        position: absolute;
        top: -30px;
        content: "";
        width: 50%;
        border: 1px dashed;
        left: 50%;
        margin-left: -25%;
      }
    }
  }

  &.preline,
  &.wrapline {
    position: relative;
  }
}

.tabs-navigation,
.main-content {
  + .c-title {
    padding-top: 0;
  }
}