.icon-lens[data-collapse-name="header-search"],
.icon-close[data-collapse-name="header-search"],
.search-icon[data-collapse-name="header-search"] {
  cursor: pointer;

  &:after {
    content: none;
  }
}

.icon-lens[data-collapse-name="header-search"] {
  vertical-align: middle;

  &:before {
    line-height: 18px;
    font-size: 1.6rem;
  }
}

.search-overlay-wrapper {
  .icon-close[data-collapse-name="header-search"] {
    cursor: pointer;
    color: text-color(reverse);
    background-color: bg-color(primary);
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }

  .search-overlay {
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: bg-color(neutrallight);
    z-index: $backdrop-zindex;
    transform-origin: right top;
    width: 0;
    height: 0;

    &.not-collapsed {
      width: 100vw;
      height: 100vh;
      overflow-y: auto;

      .row {
        width: 100%;
        height: 100%;
      }

      .search-form-column {
        display: flex;
        justify-content: center;
      }

      .header-logo {
        background-color: bg-color(reverse);
        padding: 50px 0 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-height: 155px;

        .container {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .header-search-form {
        width: 60%;
        position: relative;
      }

      .header-search-text {
        height: auto;
        width: 100%;
        text-align: left;
        font-size: rem(40);
        padding: 0 0 10px 60px;
        border-bottom: 1px solid bg-color(neutralmedium);
        background-color: $transparent;
        font-family: font-family(secondary);

        &:focus {
          color: text-color(primary);
        }

        ::placeholder {
          color: text-color(neutralmedium);
          opacity: 1;
        }

        :-ms-input-placeholder {
          color: text-color(neutralmedium);
        }

        ::-ms-input-placeholder {
          color: text-color(neutralmedium);
        }
      }

      .icon-close {
        font-size: rem(25);
      }

      .header-search-submit {
        border: none;
        background-color: $transparent;
        color: text-color(neutralmedium);
        position: absolute;
        cursor: pointer;
        left: 0;
        top: 3px;

        .icon-lens {
          &:before {
            font-size: rem(32);
          }
        }
      }
    }

    &.collapsed {
      height: 0;
      display: none;

      .icon-close[data-collapse-name="header-search"] {
        display: none;
      }

      .search-overlay {
        height: 0;
      }

      .header-search-form {
        display: none;
        opacity: 0;
      }
    }
  }
}