.blog-carousel {
  .external-icon {
    @include icon('external', before, false);
    position: absolute;
    padding: 0;
    width: 40px;
    height: 40px;
    background: bg-color(medicina);
    color: white;
    font-size: rem(12);
    line-height: 40px;
    top: -20px;
    left: auto;
    right: 0;
  }

  figure {
    width: 310px;

    .blog-image  {
      height: 160px;
    }

    figcaption {
      height: 160px;

      h5 {
        margin: 0;
      }
    }
  }
}