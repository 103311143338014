.c-numbers {
  font-family: font-family(secondary);
  text-align: center;
  background-color: bg-color(reverse);
  margin: 0 0 60px;
  padding: 45px 60px 30px;

  .c-numbers-item {

    span {
      font-size: rem(40);
    }
  }

  .c-numbers-item-label {
    font-weight: 700;
    font-size: rem(14);
    margin-top: 0;
    color: text-color(neutralmedium);
    padding: 0 20px;
  }

  .c-numbers-col-caption {
    border-top: 2px solid;

    .c-numbers-caption {
      font-size: rem(12);
      padding-top: 20px;
      margin-bottom: 0;
    }
  }
}