.c-why-unisr {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
  padding: 30px 60px;
  background-color: bg-color(reverse);

  h2 {
    margin-top: 0;
  }

  .handler-accordion {
    width: auto;
    padding-left: 0;
    &:after {
      content: none;
    }
  }
}

.c-why-unisr__content--hp {
  padding: 30px 60px;
  margin: 0;
}

.c-why-unisr__title--hp {
  margin: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

.c-why-unisr__button--hp {
  a {
    max-width: 340px;
  }
}

.c-why-unisr__content-left {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.c-why-unisr__content-right {
  flex: 1;
  position: relative;
  text-align: right;
}

.c-why-unisr__figure {
  position: relative;
  width: auto;
  height: 100%;
  object-fit: cover;
}

.c-why-unisr__description {
  position: absolute;
  bottom: auto;
  right: 0;
  left: auto;
  padding: 40px 15px 40px 50px;
  text-align: left;
  font-size: rem(16);
  line-height: 21px;
  transition: top .5s, bottom .5s ease;
  opacity: 0;

  span {
    display: block;
    height: 2px;
    width: 30px;
    position: absolute;
    left: 0;
    top: 50px;
  }
}
