.filter {
  .handler-accordion {
    @include icon(chevron, after, false);
    font-size: rem(14);
    width: 100%;
    border-color: palette(border, reverse);
    padding: 8px 14px;

    &:after {
      right: 15px;
      transform: rotate(-90deg) scaleY(-1);
      top: 27%;
    }

    &.collapsed {
      &:after {
        transform: rotate(90deg);
      }
    }

    &.not-collapsed {
      border: 1px solid $neutralmedium;
      border-bottom: 0;
    }
  }

  .target-accordion {
    background-color: bg-color(reverse);
    display: none;
    font-size: rem(14);

    &.not-collapsed {
      border: 1px solid $neutralmedium;
      border-top: 0;
    }
  }

  .wrap-filter {
    padding: 6px 14px;

    .t-unisr-news &,
    .t-unisr-search-results & {
      padding: 0;

    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      &.active {
        a {
          @include icon(close, after);
          background-color: bg-color(primary);
          color: text-color(reverse);

          &:after {
            font-size: 12px;
            line-height: 22px;
          }
        }
      }
    }

    a {
      text-decoration: none;
      padding: 8px 12px;
      color: palette(text, primary);
      display: flex;
      justify-content: space-between;

      span {
        font-size: rem(12);
        color: palette(text, neutralmedium)
      }
    }

    &.categories {
      li {
        &:not(:last-child) {
          border-bottom: 1px solid border-color(neutrallight);
        }
      }

      a {
        font-size: rem(12);
        line-height: 20px;
        padding: 10px 0 10px 0;
      }

      .active {
        a {
          background-color: bg-color(primary);
          color: text-color(reverse);

          &.medicina {
            background-color: bg-color(medicinalight);
          }

          &.psicologia {
            background-color: bg-color(psicologia);
          }

          &.filosofia {
            background-color: bg-color(filosofia);
          }
        }
      }
    }
  }

  h3 {
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 0;
  }

  &.docenti {
    margin-bottom: 20px;

    button {
      border: 0;
      background: none;
      text-transform: uppercase;
      cursor: pointer;
      letter-spacing: .3px;

      &.all {
        &.active {
          &:not(.default):not(.psicologia):not(.medicina) {
            .filosofia & {
              background-color: bg-color(filosofia);
              border: 1px solid palette(border, filosofia);
              color: palette(text, reverse);

              &:hover {
                background-color: transparent;
                color: palette(text, filosofia);
              }
            }
          }

          &:not(.default):not(.filosofia):not(.medicina) {
            .psicologia & {
              background-color: bg-color(psicologia);
              border: 1px solid palette(border, psicologia);
              color: palette(text, psicologiadark);

              &:hover {
                background-color: transparent;
                color: palette(text, psicologiadark);
              }
            }
          }

          &:not(.default):not(.psicologia):not(.filosofia) {
            .medicina & {
              background-color: bg-color(medicinalight);
              border: 1px solid palette(border, medicinalight);
              color: palette(text, medicina);

              &:hover {
                background-color: transparent;
                color: palette(text, medicinadark);
              }
            }
          }

          &:not(.default):not(.psicologia):not(.filosofia):not(.medicina) {
            .global & {
              background-color: bg-color(primary);
              border: 1px solid palette(border, brand);
              color: palette(text, reverse);
            }
          }
        }
      }

      &:not(.all):not(.handler-smalldown) {
        line-height: 20px;
        border-bottom: 2px solid rgba(0, 0, 0, 0);

        &:not(.default):not(.psicologia):not(.filosofia) {
          .medicina & {
            &:hover {
              border-bottom: 2px solid palette(border, medicina);
            }

            &.active {
              border-bottom: 2px solid palette(border, medicina);
              color: palette(text, medicina);
            }
          }
        }

        &:not(.default):not(.psicologia):not(.medicina) {
          .filosofia & {
            &:hover {
              border-bottom: 2px solid palette(border, filosofia);
            }

            &.active {
              border-bottom: 2px solid palette(border, filosofia);
              color: palette(text, filosofia);
            }
          }
        }

        &:not(.default):not(.filosofia):not(.medicina) {
          .psicologia & {
            &:hover {
              border-bottom: 2px solid palette(border, psicologiadark);
            }

            &.active {
              border-bottom: 2px solid palette(border, psicologiadark);
              color: palette(text, psicologiadark);
            }
          }
        }

        &:not(.default):not(.filosofia):not(.medicina):not(.psicologia) {
          .global & {
            &:hover {
              border-bottom: 2px solid palette(border, brand);
            }

            &.active {
              border-bottom: 2px solid palette(border, brand);
              color: palette(text, brand);
            }
          }
        }
      }
    }
  }
}