.search-overlay-wrapper {
  .search-overlay {
    &.not-collapsed {

      .header-logo {
        padding: 10px 0 4px;
        max-height: 70px;

        img {
          height: 49px;
        }
      }

      .header-search-form {
        width: 100%;
      }

      .icon-close {
        line-height: normal;
        width: 14px;
        height: 14px;
        color: text-color(neutraldark);
        background-color: transparent;
        font-size: rem(16);
      }

      .header-search-text {
        font-size: rem(24);
      }

      .header-search-submit {
        .icon-lens {
          &:before {
            font-size: rem(24);
          }
        }
      }
    }
  }
}