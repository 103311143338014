.tabs-navigation {

  .title {
    display: none;
  }

  li {
    display: inline-block;
    background-color: bg-color(reverse);
  }

  .tabs {
    text-decoration: none;
    padding: 18px 20px;
    margin-right: 5px;
    display: inline-block;

    &:not(.active):hover {
      color: tabs(default, hover-color);
      border-color: tabs(default, active-background-color);
      border-left: 4px solid;
      padding-left: 16px;
    }

    &:after {
      .filosofia & {
        background-color: palette(text, filosofia);
      }

      .medicina & {
        background-color: palette(text, medicina);
      }

      .psicologia & {
        background-color: palette(text, psicologiadark);
      }
    }

  }

  li:last-of-type {
    .tabs {
      margin-right: 0;
    }
  }
}