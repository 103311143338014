nav.main, nav.utils,
.submenu {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100vw;
  background-color: bg-color(reverse);
  left: 0;
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
}

nav.main {
  z-index: $navigation-zindex;
  display: none;
  font-weight: 300;
  height: 100%;
  position: fixed;

  .menu-in & {
    display: flex;
    position: static;

    &.is-active {
      //top: -43px;
      //position: absolute;
      //height: auto;
      //padding-right: 0;
      //padding-left: 0;

      .cta-button {
        position: static;
        display: none;
      }
    }
  }

  & > ul {
    overflow-y: auto;

    &.is-active {
      li {
        &:not(.is-active) {
          &:not(.cta-button) {
            &:not(.intranet) {
              display: none;
            }
          }
        }
      }
    }
  }

  .submenu {
    display: flex;
    position: absolute;
    top: 0;
    height: 100%;
    z-index: $navigation-zindex + 4;
    transform: translateX(200%);
    overflow-y: auto;

    &.is-active {
      position: fixed;
      //display: flex;
      //top: 0;
      height: 100%;
      transform: none;
    }

    .container {
      position: static;

      .card-column {
        position: static;
        padding-right: 0;

        .menucard {
          position: static;

          p {
            display: none;
          }

          .button-secondary {
            display: none;
            position: absolute;
            bottom: 0;
            font-size: rem(14);
          }
        }

        & ~ .column.col-md-3 {
          margin-bottom: 30px;

          &:not(:nth-child(2)) {
            .block:first-child {
              ul {
                margin-top: -30px;
              }
            }
          }
        }
      }
    }

    ul {
      li:not(.cta-button) {
        padding: 10px 0;
      }
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li:not(.cta-button) {
      padding: 20px 0;

      &.has-submenu {
        border-bottom: 1px solid palette(border, neutrallight);
      }

      a {

        &.active {
          margin: 0 -15px;
          padding: 10px 15px;
          //background-color: bg-color(neutrallight);

          &.block-title {
            padding-left: 35px;
          }
        }
      }
    }
  }

  .goback {
    @include icon(chevron, before, false);
    font-weight: 700;
    padding: 20px 15px 20px 0;
    text-transform: none;

    &:before {
      transform: rotate(180deg);
      background-color: bg-color(neutrallight);
      height: 23px;
      width: 23px;
      text-align: center;
      font-size: rem(8);
      vertical-align: middle;
      padding: 5px 0;
      margin-right: 7px;
    }

    .menu-icon {
      margin-left: auto;
      margin-right: 3.5px;
      text-align: right;
    }
  }

  .has-submenu {
    > a {
      @include icon(chevron, after, false);

      &:after {
        float: right;
        background-color: bg-color(neutrallight);
        height: 23px;
        width: 23px;
        text-align: center;
        //color: white;
        font-size: 8px;
        vertical-align: middle;
        padding: 5px 0;
        margin-left: 30px;

      }
    }
  }

  .expandable {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;

    &.is-active {
      opacity: 1;
      visibility: visible;
      position: relative;
      background-color: bg-color(neutrallight);
      margin: auto -15px;
      padding-left: 15px;
      font-size: rem(14);
      padding-bottom: 20px;
      border-bottom: 1px solid palette(border, reverse);
    }

    li {
      text-transform: none;
    }
  }

  .block-title {
    padding: 10px 20px;

    &.is-active {
      margin: 0 -15px;
      padding: 10px 35px;
      background-color: bg-color(neutrallight);
    }

    &.handler {
      @include icon(plus_accordion, after, false);

      &.is-active {
        @include icon(minus_accordion, after, false);
      }

      &:after {
        float: right;
        font-size: rem(10);
        margin-top: 2px;
      }
    }
  }
}

.cta-button {
  position: absolute;
  bottom: 0;
  flex-direction: column;
  left: 0;
  width: 100%;
  padding: 0 15px 10px 15px;

  a {
    display: flex;
    align-items: center;
    width: 100%;

    &:first-child {
      margin-bottom: 10px;
    }
  }
}

nav.utils {
  margin-bottom: 120px;

  .contacts {
    &:after {
      padding-right: 10px;
      margin-right: 5px;
      border-right: 1px solid palette(border, neutralverylight);
    }
  }

  .intranet, .alumni, .unisr-plus {
    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .external {
    @include icon(plus_accordion, after, false);
    display: flex;
    justify-content: space-between;
    padding: 0;
    background-color: transparent;
    width: 100%;

    &:after {
      width: 30px;
      height: 30px;
      line-height: 31px;
    }

    &.is-active {
      @include icon(minus_accordion, after, false);
    }
  }
}

nav.is-active {
  & > .navigator-target {
    & > li {
      &:not(.is-active):not(.cta-button):not(.lang):not(.contacts):not(.intranet) {
        //display: none;
      }
    }
  }
}

.search {
  display: none;
}

.has-submenu {
  &.is-active {
    padding-top: 0;
  }
}

.mobile-only {

  &.button-secondary {
    display: inline-block;
    font-family: font-family(secondary);
    font-weight: 600;
    font-size: rem(14);
    padding-top: 10px;
    padding-right: 24px;
    padding-bottom: 10px;
    margin: auto 30px 20px 30px;
    position: relative;
    @include icon(chevron, after, false);

    &:after {
      height: 23px;
      width: 23px;
      text-align: center;
      font-size: rem(14);
      vertical-align: middle;
      position: absolute;
      right: 7px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}