.news-controls {
  .row {
    border-bottom-width: 0;
    padding-bottom: 0;
  }

  h4 {
    display: none;
  }

  .col-6 {
    padding: 0;
  }

  .prev, .next {
    p {
      display: none;
    }
  }
}