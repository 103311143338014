.breadcrumb {
  background-color: bg-color(neutraldark);

  ol {
    list-style: none;
    margin: 8px 0;

    li {
      display: inline-block;
    }

    a {
      font-weight: 300;
      position: relative;
      font-size: rem(12);
      text-decoration: none;
      color: text-color(reverse);

      &:not(.current) {
        padding-right: 13px;
        margin-right: 6px;
        @include icon(chevron, after, false);

        &:after {
          @include center(y);
          display: inline-block;
          position: absolute;
          right: 0;
        }
      }
    }
  }
}