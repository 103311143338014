.c-profile-cards {
  & > .container {
    .t-unisr-course & {
      padding: 0 7px;
    }
  }
  .carousel-wrapper {
    width: 100%;
    .real-carousel {
      display: flex;
      justify-content: space-evenly;
      .profile-card-item {
        background-color: bg-color(reverse);
        padding: 0;
        margin: 0 7.5px !important;
      }
    }
  }
}