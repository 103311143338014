.tabs-navigation {
  margin-bottom: 30px;

  .tabs {
    &.active {
      font-weight: 600;
      border-color: tabs(default, active-background-color);
      border-left: 4px solid;
      //pointer-events: none;
      cursor: default;
      padding-left: 16px;
    }

    .filosofia & {
      &.active {
        border-color: palette(border, filosofia);
        //pointer-events: none;
      }

      &:not(.active):hover {
        border-color: palette(border, filosofia);
      }
    }

    .medicina & {
      &.active {
        border-color: palette(border, medicina);
        //pointer-events: none;
      }

      &:not(.active):hover {
        border-color: palette(border, medicina);
      }
    }

    .psicologia & {
      &.active {
        border-color: palette(border, psicologia);
        //pointer-events: none;
      }

      &:not(.active):hover {
        border-color: palette(border, psicologia);
      }
    }
  }

  ul {
    padding-left: 0;
    margin: 0;
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: column;

    li {
      a {
        width: 100%;
      }
    }
  }

  .tabs-container {
    li {
      display: list-item;
    }

  }
}