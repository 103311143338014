.t-unisr-contacts {

  .container-fluid {
    padding: 0;
  }

  .main-content {
    .widget {
      &:first-child {
        margin-top: 20px;
      }
    }
  }

  .wrapper-banner {
    h1 {
      margin-bottom: 15px;
    }
    p {
      font-size: rem(14);
      color: text-color(reverse);
    }
  }

  .facultycard  {
    margin: 0 0 15px;
    background: white;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: 25px 0 30px;
      background: white;
    }

    h2 {
      font-size: rem(18);
      text-align: left;
    }

    a {
      @include icon(chevron, after);
      margin-left: auto;
      position: relative;
      background-color: buttons(chat, background-color);
      color: buttons(filosofia, color);
      &:hover {
        background-color: buttons(chat, hover-background-color);
        color: buttons(chat, color);
      }
    }

    &:nth-of-type(2n) {
      .button-primary {
        background-color: buttons(meeting, background-color);
        color: buttons(meeting, color);
        &:hover {
          background-color: buttons(meeting, hover-background-color);
        }
      }
    }

  }

  .facultycard-image {
    display: flex;
    width: 66px;
    height: 66px;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid border-color(neutrallight);
    border-radius: 50%;
  }

}

