.c-imgtext-card {
  .textimg-card-item{
    margin-top: 30px;
  }
  .card-img {
    height: calc(100vw/1.50133);
    img{
      height: 100%;
    }
  }
}