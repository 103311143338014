.t-unisr-search-results {
  .main-content {
    padding-top: 0;
    padding-bottom: 40px;

    .col-md-8 {
      padding: 0;

      h4 {
        font-weight: normal;
        font-size: rem(23);
        padding: 0 37px;
      }

      .search-results-wrapper {
        margin-bottom: 35px;

        .search-results-item {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .pagination {
        padding-bottom: 40px;
      }
    }
  }

}