.tns-outer {
  position: relative;
}

.tns-controls,
.tns-nav {
  position: absolute;
  z-index: $carouselcontrols;
  right: 0;
  bottom: 0;
}

.tns-nav {
  text-align: center;
  bottom: 20px;
  button {
    padding: 0;
    border: 0;
    margin-right: 20px;
    .t-unisr-homepage & {
      width: 70px;
      height: 5px;
      background: rgba(255, 255, 255, 0.5);

      &.tns-nav-active {
        background: rgba(255, 255, 255, 0.85);
      }
    }

    body:not(.t-unisr-homepage) & {
      background: rgba(75, 75, 75, 0.5);
      width: 12px;
      height: 12px;
      border-radius: 50%;

      &.tns-nav-active {
        background: rgba(75, 75, 75, 0.85);
      }
    }

    &:focus,
    &:active {
      outline: none;
    }
  }

  body:not(.t-unisr-homepage) & {
    bottom: -55px;
  }
}

.tns-controls {
  pointer-events: none;
  outline: none;

  button {
    pointer-events: auto;
    background-color: rgba(255, 255, 255, 0.33);
    border: none;
    font-size: 0;

    &:first-of-type {
      @include icon(chevron, before);
      float: left;

      &:before {
        transform: rotate(180deg);
      }
    }

    &:last-of-type {
      @include icon(chevron, before);
      float: right;
    }

    .t-unisr-homepage & {
      &:before {
        font-size: rem(20);
        color: palette(text, primary);
      }
    }

    body:not(.t-unisr-homepage) & {
      &:before {
        font-size: rem(26);
        color: palette(text, brand);
      }
    }
  }

  body:not(.t-unisr-homepage) & {
    top: calc(50% - 15px);
  }
}

.slide {
  font-size: 0;

  img {
    width: 100%;
  }
}

.carousel-nav {
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 80px;
  vertical-align: middle;
  z-index: $carouselcontrols;
}