.modal-container {
  &.not-collapsed {
    .modal-content-container {
      margin: 0;
      padding: 15px;
      max-height: unset;
      height: 100%;
    }
  }

  .event-data {
    li {
      display: block;

      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
}