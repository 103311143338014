// _u_utilname
.text-center {
  text-align: center;
}

.clearfix {
  clear: fix;
}

[data-linkManager-href]:not([data-linkManager-href='']) {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}