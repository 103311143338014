.modal-calendar-item {
  .calendar-events-list {
    padding: 5px;

    .calendar-event {
      .event-title {
        padding-right: 30px;
      }

      .event-description {
        font-size: rem(14);
        line-height: 21px;
      }

      .subscribe-button {
        width: 280px;
      }
    }
  }
}