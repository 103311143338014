footer.main {
  .lower-bar {
    text-align: center;

    .row {
      div {
        &:first-child {
          border-bottom: 2px solid border-color(reverse);
        }
      }
    }

    li {
      padding: 10px 0;
    }
  }

  .upper-bar {
    padding-bottom: 40px;

    .col-md-3 {
      h3 {
        padding: 30px 15px;
        text-align: center;

        &:not(.follow-on-social) {
          text-transform: uppercase;
        }

        &.customer-care {
          text-align: center;
          border-bottom: 1px solid;
          padding-top: 6px;
        }

        &.follow-on-social {
          padding-top: 6px;
          padding-bottom: 6px;
        }
      }
    }
  }

  .handler-accordion {
    margin: 0;

    &.collapsed {
      border-bottom: 1px solid;
    }

    &:after {
      right: 15px;
    }
  }

  .target-accordion {
    list-style: none;

    a {
      font-size: rem(13);
      padding: 6px;
      display: inline-block;
    }
  }

  .social-icon {
    width: 80%;
    margin: 15px auto;
    text-align: center;

    li {
      text-align: center;
      padding: 0 10px 10px 10px;
    }
  }

  .newsletter-btn {
    margin: 20px auto;
    display: block;
    width: 65%;
  }
}