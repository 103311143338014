.modal-container {
  &.not-collapsed {
    &.target-modal {
      justify-content: flex-start;

      .header-logo {
        position: static;
        flex-basis: auto;
        padding: 20px 0 10px;

        .container {
          justify-content: center;

          .icon-close {
            display: none;
          }
        }
      }

      .c-editorial-cards {
        padding: 0;

        & > h2 {
          background-color: bg-color(reverse);
          top: auto;
          font-size: rem(34);
          position: relative;
          padding-bottom: 24px;
          margin: 0 0 20px;

          &:before {
            content: "";
            background-color: bg-color(reverse);
            left: -15px;
            height: 100%;
            width: 15px;
            position: absolute;
          }

          &:after {
            content: "";
            background-color: bg-color(reverse);
            right: -15px;
            height: 100%;
            width: 15px;
            position: absolute;
          }
        }

        & > h6 {
          background-color: bg-color(reverse);
          top: auto;
          font-size: rem(12);
          position: relative;
          margin: 0;
          padding: 16px 0 4px;

          &:before {
            content: "";
            background-color: bg-color(reverse);
            top: 0;
            left: -15px;
            height: 100%;
            width: 15px;
            position: absolute;
          }

          &:after {
            content: "";
            background-color: bg-color(reverse);
            top: 0;
            right: -15px;
            height: 100%;
            width: 15px;
            position: absolute;
          }
        }
      }

      .icon-close {
        &.mobile-only {
          display: block;
          align-self: center;
          width: 40px;
          height: 40px;
          margin: auto;
          line-height: 40px;
          font-size: 14px;
        }
      }
    }
  }
}