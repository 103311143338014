.stick {
  &.tabs-navigation {
    width: 100%;
    z-index: $navigation-zindex;
    margin-top: 0;
    left: 0;

    .container {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

.sticky {
  &.cta-buttons {
    position: fixed;
    display: flex;
    width: 100%;
    margin-bottom: 40px;
    bottom: -200px;
    transition: .3s ease-in;
    z-index: $navigation-zindex;
  }

  &.stick {
    top: initial;
    bottom: 0;
    margin-bottom: 0;

    button {
      &:first-child {
        color: text-color(reverse);
      }
    }
  }
}