.pagination {

  li {
    line-height: 48px;

    &.prev-item {
      margin-right: 9px;
    }

    &.next-item {
      margin-left: 9px;
    }
  }

  a {
    width: 48px;
    height: 48px;
  }
}