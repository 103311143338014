body {
  .main-content {
    padding: 30px 0 170px;
  }
  &.t-unisr-homepage {
    .main-content {
      padding-top: 0;
    }
  }
  &.t-unisr-news {
    .main-content {
      padding-bottom: 0;
    }
  }

}

.stick {
  position: sticky;
  top: 0;
}