.bg-light {
  background-color: bg-color(reverse);
}

.mirrored {
  .row {
    flex-direction: row-reverse;
  }
}

.widget {
  &:not(:last-child) {
    margin-bottom: 60px;
  }

  &.c-text {
    & + .c-media {
      margin-top: -100px;
    }
  }

  &.bg-light {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 10px;
    margin-bottom: 10px;

    & + .bg-light {
      margin-top: -10px;
    }
  }

  a:not(.button-primary):not(.button-download):not(.link-item):not(.modal-close):not(.course-boxes-link), li:before, .c-numbers-item span, &.cta-buttons a {

    .filosofia & {
      color: palette(text, filosofia);
    }

    .medicina & {
      color: palette(text, medicina);
    }

    .psicologia & {
      color: palette(text, psicologia);
    }
  }

  blockquote:before {
    color: palette(text, neutrallight);

    .filosofia & {
      color: palette(text, filosofialight);
    }

    .medicina & {
      color: palette(text, medicinalight);
    }

    .psicologia & {
      color: palette(text, psicologialight);
    }
  }

  h2, h3, h4, h5, h6, .button-text.visible {
    &:before {
      .filosofia & {
        background-color: palette(text, filosofia);
      }

      .medicina & {
        background-color: palette(text, medicina);
      }

      .psicologia & {
        background-color: palette(text, psicologia);
      }
    }
  }

  &.cta-buttons.sticky, &.cta-buttons a:first-child {

    color: text-color(reverse) !important;
    border: none;

    .filosofia & {
      background-color: palette(text, filosofia);
    }

    .medicina & {
      background-color: palette(text, medicina);
    }

    .psicologia & {
      background-color: palette(text, psicologia);
    }
  }

  .button-text.visible, .c-numbers-col-caption, &.cta-buttons a:nth-child(2) {
    .filosofia & {
      border-color: palette(text, filosofia);
    }

    .medicina & {
      border-color: palette(text, medicina);
    }

    .psicologia & {
      border-color: palette(text, psicologia);
    }
  }

  p {
    margin-top: 0;
  }
}

.c-title {
  + .c-boxes {
    margin-top: -25px;

    &.bg-light {
      margin-top: -10px;
    }
  }
}

.c-text, .c-albo {
  a {
    color: text-color(medicina);
    text-decoration: none;
    border: none;

    &:hover {
      color: text-color(medicinadark);
    }

    &.pdf, &.docx {

      &.button-download {
        font-family: font-family(primary);
        font-size: rem(16);
        line-height: 21px;
        margin-bottom: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        color: text-color(primary);

        &:before, &:after {
          color: text-color(medicina);
        }

        &:nth-of-type(2) {
          border-top: 0;
        }
      }
    }

  }
}

.c-text {
  div {
    &.document-download {
      border: none;
      margin: 0 9px;
      padding-top: 20px;
      color: text-color(primary);

      &:not(:first-of-type) {
        border-top: 1px solid border-color(neutrallight);
      }

      &:first-of-type {
        border-bottom: 1px solid border-color(neutrallight);
        margin-top: 20px;
      }

      &:nth-of-type(2) {
        border-top: 0;
      }
    }
  }
}