.c-boxes {
  &.widget {
    .left-nav & {
      margin-bottom: 0;
    }

    .container {
      padding: 0;

      .row {
        .col-12 {
          .inner {
            .links {
              a {
                color: text-color(primary);
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-right: 48px;
              }
            }
          }
        }
      }
    }
  }

  .inner {

    p {
      font-size: rem(14);
    }
  }

  a {
    color: palette(text, primary);
  }

  h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }

  &.widget {
    .links {
      font-size: rem(14);

      a {
        @include icon(chevron, after);
        color: palette(text, primary);
        background-color: bg-color(reverse);
        display: block;
        text-decoration: none;
        position: relative;
        padding: 18px 20px;
        word-break: break-word;
        transition: text-shadow .1s;

        &:after {
          color: text-color(primary);
          background-color: bg-color(neutrallight);
          height: 22px;
          width: 22px;
          text-align: center;
          line-height: 22px;
          padding-left: 1px;
          font-size: rem(10);
          position: absolute;
          right: 20px;

          .filosofia & {
            background-color: palette(text, filosofia);
            color: text-color(reverse);
          }

          .medicina & {
            background-color: palette(text, medicina);
            color: text-color(reverse);
          }

          .psicologia & {
            background-color: palette(text, psicologia);
            color: text-color(reverse);
          }
        }

      }

      .filosofia & {
        a {
          &:before,
          &:hover {
            color: palette(text, filosofia);
          }
        }
      }

      .medicina & {
        a {
          &:before,
          &:hover {
            color: palette(text, medicina);
          }
        }
      }

      .psicologia & {
        a {
          &:before,
          &:hover {
            color: palette(text, psicologia);
          }
        }
      }
    }
  }

  &.bg-light {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

}

.c-course-boxes {

  &:not(.global) {
    h2 {
      padding-left: 120px;
      padding-right: 110px;
    }
  }

  .col-md-4 {
    h2 {
      padding-right: 40px;
    }
  }

  a {
    font-size: rem(14);
    font-weight: 700;
    padding-left: 120px;

    &:after {
      margin-left: 60px;
      color: #fff;
      font-weight: 400;
      height: 22px;
      width: 22px;
      text-align: center;
      line-height: 22px;
      font-size: rem(10);
    }
  }

  .links {
    font-size: rem(14);
    display: flex;
    align-items: center;
    padding: 0;

    a {
      @include icon(chevron, after);
      color: palette(text, primary);
      background-color: bg-color(reverse);
      font-size: rem(28);
      font-weight: 700;
      text-decoration: none;
      position: relative;
      line-height: rem(28);
      word-break: break-word;
      transition: text-shadow .1s;
      margin: 10px 0;
      padding: 40px 140px 40px 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;

      &.filosofia {
        &:after, &:before {
          background-color: palette(text, filosofia);
          color: text-color(reverse);
        }
      }

      &.medicina {
        &:after, &:before {
          background-color: palette(text, medicina);
          color: text-color(reverse);
        }
      }

      &.psicologia {
        &:after, &:before {
          background-color: palette(text, psicologia);
          color: text-color(reverse);
        }
      }

      &:before {
        content: "";
        width: 50px;
        left: 0;
        position: absolute;
        background-color: bg-color(neutrallight);
        height: 3px;
        top: 50%;
        transform: translateY(-50%);

        .filosofia & {
          background-color: palette(text, filosofia);
        }

        .medicina & {
          background-color: palette(text, medicina);
        }

        .psicologia & {
          background-color: palette(text, psicologia);
        }
      }

      &:after {
        color: text-color(primary);
        background-color: bg-color(neutrallight);
        height: 22px;
        width: 22px;
        text-align: center;
        line-height: 22px;
        padding-left: 1px;
        font-size: rem(10);
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);

        .filosofia & {
          background-color: palette(text, filosofia);
          color: text-color(reverse);
        }

        .medicina & {
          background-color: palette(text, medicina);
          color: text-color(reverse);
        }

        .psicologia & {
          background-color: palette(text, psicologia);
          color: text-color(reverse);
        }
      }
    }

    .filosofia & {
      a {
        &:before,
        &:hover {
          color: palette(text, filosofia);
        }
      }
    }

    .medicina & {
      a {
        &:before,
        &:hover {
          color: palette(text, medicina);
        }
      }
    }

    .psicologia & {
      a {
        &:before,
        &:hover {
          color: palette(text, psicologia);
        }
      }
    }

    .tooltip {
      position: absolute;
      right: 90px;
      background-color: bg-color(neutrallight);
      border-radius: 50%;
      font-size: 12PX;
      width: 22px;
      height: 22px;
      padding: 0;
      margin: 0;
      line-height: 22px;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;

      .filosofia & {
        background-color: palette(text, filosofia);
        color: text-color(reverse);
      }

      .medicina & {
        background-color: palette(text, medicina);
        color: text-color(reverse);
      }

      .psicologia & {
        background-color: palette(text, psicologia);
        color: text-color(reverse);
      }

      &:hover {
        border-radius: 0;
        border-top-right-radius: 50%;
        border-top-left-radius: 50%;

        .extra-info {
          display: flex;

          p {
            background-color: bg-color(reverse);
          }
        }
      }
    }

    .extra-info {
      color: text-color(primary);
      background-color: bg-color(reverse);
      box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.07);
      border-top: 2px solid;
      display: none;
      position: absolute;
      z-index: 1;
      right: 0;

      .filosofia & {
        border-color: palette(text, filosofia);
      }

      .medicina & {
        border-color: palette(text, medicina);
      }

      .psicologia & {
        border-color: palette(text, psicologia);
      }

      .icon-block {
        display: flex;
        align-items: center;
        padding: 24px;
        border-style: none;

        &:before {
          display: block;
          font-size: rem(30);
          margin-right: 16px;

          .filosofia & {
            color: palette(text, filosofia);
          }

          .medicina & {
            color: palette(text, medicina);
          }

          .psicologia & {
            color: palette(text, psicologia);
          }
        }
      }

      p {
        margin: 0;
        text-align: center;
      }

      .access-icon {
        @include icon(access, before);
      }

      .atom-icon {
        @include icon(atom, before);
      }

      .chain-icon {
        @include icon(chain, before);
      }

      .credits-icon {
        @include icon(credits, before);
      }

      .dna-icon {
        @include icon(dna, before);
      }

      .flask-icon {
        @include icon(flask, before);
      }

      .language-icon {
        @include icon(language, before);
      }

      .meters-icon {
        @include icon(meters, before);
      }

      .researcher-icon {
        @include icon(researcher, before);
      }

      .time-icon {
        @include icon(time, before);
      }

    }
  }
}

.c-info-boxes {
  display: flex;
  flex-wrap: wrap;

  &.widget {
    margin-bottom: 0;
  }

  .container {
    padding: 0;
  }

  & > h2, h3, h4 {
    flex-grow: 1;
    margin-top: 0;
    margin-bottom: 16px;
  }

  & > a {
    display: flex;
    align-items: center;
    font-size: rem(14);
    margin-bottom: 26px;

    &.with-arrow {
      font-weight: 700;
      padding-right: 0;
      padding-left: 0;

      &:after {
        background-color: bg-color(reverse);
        color: text-color(primary);
        height: 23px;
        width: 23px;
        text-align: center;
        font-size: 8px;
        vertical-align: middle;
        line-height: 23px;
        padding-left: 2px;
        margin-left: 30px;
      }
    }
  }

  .links {
    font-size: rem(14);

    &.col-md-4 {
      margin-bottom: 60px;
      min-height: auto;
    }

    a {
      @include icon(chevron, after);
      color: palette(text, primary);
      background-color: bg-color(reverse);
      display: block;
      text-decoration: none;
      position: relative;
      padding: 12px 40px;
      word-break: break-word;
      transition: text-shadow .1s;
      min-height: 76px;

      &:after {
        color: text-color(primary);
        background-color: bg-color(neutrallight);
        height: 22px;
        width: 22px;
        text-align: center;
        line-height: 22px;
        padding-left: 1px;
        font-size: rem(10);
        position: absolute;
        right: 30px;

        .filosofia & {
          background-color: palette(text, filosofia);
          color: text-color(reverse);
        }

        .medicina & {
          background-color: palette(text, medicina);
          color: text-color(reverse);
        }

        .psicologia & {
          background-color: palette(text, psicologia);
          color: text-color(reverse);
        }
      }

    }

    .filosofia & {
      a {
        &:before,
        &:hover {
          color: palette(text, filosofia);
        }
      }
    }

    .medicina & {
      a {
        &:before,
        &:hover {
          color: palette(text, medicina);
        }
      }
    }

    .psicologia & {
      a {
        &:before,
        &:hover {
          color: palette(text, psicologia);
        }
      }
    }
  }

  .inner {
    padding: 12px 0;
    background-color: bg-color(reverse);

    h6 {
      font-size: rem(12);
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 40px;
      margin: 0;
    }

    .links {
      a {
        color: text-color(primary);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 60px;
        min-height: 54px;
      }
    }
  }

  .links {

    .course-boxes-link {
      @include icon(chevron, after);
      color: palette(text, primary);
      background-color: bg-color(reverse);
      font-size: rem(28);
      font-weight: 700;
      text-decoration: none;
      position: relative;
      line-height: rem(28);
      word-break: break-word;
      transition: text-shadow .1s;
      margin: 10px 0;
      padding: 14px 60px 14px 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;

      &.faculty-colored {
        &.filosofia {
          &:after, &:before {
            background-color: palette(text, filosofia);
            color: text-color(reverse);
          }

          &:hover {
            color: text-color(filosofia);
          }
        }

        &.medicina {
          &:after, &:before {
            background-color: palette(text, medicina);
            color: text-color(reverse);
          }

          &:hover {
            color: text-color(medicina);
          }
        }

        &.psicologia {
          &:after, &:before {
            background-color: palette(text, psicologia);
            color: text-color(reverse);
          }

          &:hover {
            color: text-color(psicologia);
          }
        }
      }

      &:before {
        content: "";
        width: 50px;
        left: 0;
        position: absolute;
        background-color: bg-color(neutrallight);
        height: 3px;
        top: 50%;
        transform: translateY(-50%);

        .filosofia & {
          background-color: palette(text, filosofia);
        }

        .medicina & {
          background-color: palette(text, medicina);
        }

        .psicologia & {
          background-color: palette(text, psicologia);
        }
      }

      &:after {
        color: text-color(primary);
        background-color: bg-color(neutrallight);
        height: 22px;
        width: 22px;
        text-align: center;
        line-height: 22px;
        padding-left: 1px;
        font-size: rem(10);
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);

        .filosofia & {
          background-color: palette(text, filosofia);
          color: text-color(reverse);
        }

        .medicina & {
          background-color: palette(text, medicina);
          color: text-color(reverse);
        }

        .psicologia & {
          background-color: palette(text, psicologia);
          color: text-color(reverse);
        }
      }

      &:before {
        content: "";
        width: 30px;
        left: 0;
        position: absolute;
        background-color: bg-color(neutrallight);
        height: 3px;
        top: 50%;
        transform: translateY(-50%);

        .filosofia & {
          background-color: palette(text, filosofia);
        }

        .medicina & {
          background-color: palette(text, medicina);
        }

        .psicologia & {
          background-color: palette(text, psicologia);
        }
      }

    }

    .filosofia & {
      a {
        &:before,
        &:hover {
          color: palette(text, filosofia);
        }
      }
    }

    .medicina & {
      a {
        &:before,
        &:hover {
          color: palette(text, medicina);
        }
      }
    }

    .psicologia & {
      a {
        &:before,
        &:hover {
          color: palette(text, psicologia);
        }
      }
    }
  }

  .list {
    margin-bottom: 60px;
  }
}