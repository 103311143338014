.t-unisr-target-detail {
  &:not(.not-overlay) {

    .main-content {
      .widget.c-info-boxes {
        &:first-child {
          margin-top: -89px;
        }

        .course-boxes-link {
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 45px;
        }
      }
    }
  }
}
