.c-static-map {
  margin-bottom: 15px;
  padding: 0;

  .textual-info {
    left: 15px;
    padding: 30px 70px;
    z-index: $static-map-zindex;
    display: flex;
    flex-direction: column;
  }

  h3 {
    margin-top: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    &:before {
      position: absolute;
      width: 50px;
      height: 2px;
      left: 0;
      content: "";
      background-color: $medicina;
    }
  }

  .icon {
    flex: 1 0 25%;
    max-width: 25%;
  }

  &.mirror {
    .textual-info {
      left: auto;
      right: 15px;
    }
  }
}
