.c-text-image {
  .caption-col {
    max-width: calc(50% - 140px);
    border-top: 2px solid border-color(neutrallight);
    margin: 0 70px;
  }
  .c-numbers {
    .col-4 {
      padding-right: 15px;
    }
  }
}