.c-text {
  padding: 22px 10px 30px;
  margin-right: -15px;
  margin-left: -15px;

  h2, h3, h4 {
    &:before {
      width: 15px;
      left: 0;
    }
  }

  .document-download {
    .document-caption {
      &:before {
        content: none;
      }
    }
  }

  //NOTE Why I added this? TODO Find out why, it should matters in one page but I can't find which one

  .readmore-text {
    p {
      &:first-child {
        margin-top: 11px;
      }
    }
  }

  .button-text.cv-read-more {
    padding-bottom: 0;
  }

  & + .c-text {
    margin-top: -94px //NOTE to check and be optimized
  }

  &.internal_c-text {
    //padding: 22px 0;
    margin-bottom: 30px;

    .cv-read-more.visible {
      padding-bottom: 30px;

      &:before {
        color: text-color(reverse);
        background-color: bg-color(neutralmedium);
      }
    }

    & + .c-text {
      margin-top: -22px
    }
    /*
    .handler-accordion {
      &.title {
        position: relative;
        font-size: rem(16);
        font-weight: 700;
        min-height: 70px;
        padding: 10px 45px 10px 0;
        margin: 0;

        &:before {
          content: none;
        }

        &:after {
          display: inline-block;
          right: 0;
          width: 40px;
          height: 40px;
          line-height: 40px;
          background-color: bg-color(neutrallight);
          text-align: center;
        }

      }
    }
    */
  }
}