.c-editorial-cards {
  &.with-bg {
    h2, h6, .editorialcard:first-child picture {
      &:before, &:after {
        width: calc(50vw + 697px);
      }

      &:before {
        left: 8px;
      }
    }

    & .container {
      padding: 0;
    }
  }
}
