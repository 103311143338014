$headerheight: 82px;
$utilityheaderheight: 43px; 

nav.main,
.submenu {
  height: calc(100vh - #{($utilityheaderheight + $headerheight)});
}

nav.main {
  top: $headerheight;
}


