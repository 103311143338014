.news-tags {
  padding: 50px 15px 20px 0;

  .col-12 {
    padding: 0 30px;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: inline-block;
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }

    h5 {
      margin-bottom: 10px;
    }

    a {
      padding: 5px;
      border: 1px solid;
      color: palette(text, neutraldark);
      text-decoration: none;
      border-radius: 3px;
      font-size: rem(12);
      border-radius: 3px;

      &:hover {
        color: palette(text, reverse);
        background-color: bg-color(primary);
        border-color: palette(text, primary);
      }
    }
  }
}