.pagination {
  width: 100%;
  text-align: center;

  ul {
    list-style: none;
    display: inline-block;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    vertical-align: middle;
    background-color: bg-color(reverse);
    line-height: 60px;

    &.hide {
      a:before {
        opacity: 0.2;
      }
    }

    &.prev-item {
      margin-right: 2px;
    }

    &.next-item {
      margin-left: 2px;
    }
  }

  a {
    width: 60px;
    height: 60px;
    text-align: center;
    padding: 0;
    color: palette(text, neutraldark);
    display: block;
    text-decoration: none;
    font-size: rem(14);
    font-weight: 700;

    &.next {
      @include icon(chevron, before);
      border-left: 2px solid bg-color(neutral);
    }

    &.prev {
      @include icon(chevron, before);

      &:before {
        transform: rotate(180deg);
      }
    }

    &.active {
      background-color: bg-color(primary);
      color: palette(text, reverse);
      border-color: palette(border, brand);

      .filosofia & {
        background-color: bg-color(filosofia);
        border-color: palette(border, filosofia);
      }

      .medicina & {
        background-color: bg-color(medicinalight);
        border-color: palette(border, medicinalight);
        color: palette(text, medicinadark);
      }

      .psicologia & {
        background-color: bg-color(psicologia);
        border-color: palette(border, psicologia);
        color: palette(text, psicologiadark);
      }
    }
  }
}
