.t-unisr-error {
  .main-content {
    padding: 0 0 170px 0;

    .error-wrapper {
      height: 448px;
      width: 100%;
      background-size: cover;
      background-position: center;

      .error-box {
        h6 {
          font-size: rem(14);
          margin-bottom: 15px;
        }

        h1 {
          font-size: rem(40);
          margin: 0;
          line-height: 40px;

          &:before {
            content: "";
            width: 50px;
            left: 0;
            margin-top: 18px;
            position: absolute;
            background-color: bg-color(neutrallight);
            height: 3px;
          }
        }

        padding: 38px 77px 32px 70px;
        background-color: white;
        top: 60px;
        margin-left: 134px;
        max-width: 571px;

        .button-primary.with-arrow {
          margin-top: 28px;
          font-weight: 700;
          font-size: rem(14);
          padding-right: 0;
          padding-left: 0;

          &:after {
            background-color: bg-color(neutrallight);
            color: #333;
            height: 24px;
            width: 24px;
            text-align: center;
            font-size: 8px;
            vertical-align: middle;
            line-height: 23px;
            padding-left: 2px;
            margin-left: 20px;
          }
        }
      }
    }
  }
}