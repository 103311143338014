.c-boxes {
  .col-md {
    flex: 1 1 0;
    width: 0;
  }
}

.accordion-boxes {
  h2 {
    margin-top: 0;
  }
}

.c-course-boxes {
  h2 {
    margin-top: 10px;
  }

  &.filosofia {
    h2, .with-arrow {
      &:before, &:after {
        background-color: palette(text, filosofia);
      }
    }

    h2 {
      @include icon(filosofia_icon, before, false);

      &:before, &:after {
        background-color: palette(text, filosofia);
      }
    }
  }

  &.medicina {
    h2, .with-arrow {
      &:before, &:after {
        background-color: palette(text, medicina);
      }
    }

    h2 {
      @include icon(medicina_icon, before, false);

      &:before {
        background-color: palette(text, medicina);
      }
    }
  }

  &.psicologia {
    h2, .with-arrow {
      &:before, &:after {
        background-color: palette(text, psicologia);
      }
    }

    h2 {
      @include icon(psicologia_icon, before, false);

      &:before {
        background-color: palette(text, psicologia);
      }
    }
  }

  &.interfacolta {
    h2, .with-arrow {
      &:before, &:after {
        background-color: palette(text, neutralmedium);
      }
    }

    h2 {
      @include icon(interfacolta_icon, before, false);

      &:before {
        background-color: palette(text, neutralmedium);
      }
    }
  }

  h2 {
    &:before {
      color: text-color(reverse);
      position: absolute;
      left: 15px;
      width: 90px;
      height: 80px;
      font-size: 50px;
      line-height: 80px;
      text-align: center;
    }
  }
}

.c-info-boxes {
  h2 {
    & + .container {
      .row {
        .list {
          &:first-child, &:nth-child(2), &:nth-child(3) {
            margin-top: 0;
          }
        }
      }
    }
  }
  .course-boxes-link, .inner {
    height: 100%;
  }
  &.has-title {
    .links {
      a {
        &:first-child {
          margin-top: 39px;
        }
      }
    }
  }
}