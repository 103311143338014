.t-unisr-course, .t-unisr-tabs-child, .t-unisr-internal {
  .course-highlight {
    margin-top: -112px;
    margin-bottom: 30px;

    .icon-row {
      padding: 0;

      .icon-block {
        border-style: none;
      }
    }

    .brochure-col {
      border-left: 6px solid;
      background-color: bg-color(reverse);
      padding-left: 30px;

      h4 {
        font-family: font-family(secondary);
        font-weight: 700;
        margin-bottom: 0;
      }

      & > p {
        font-size: rem(14);
        margin: 0 0 10px;
      }

      .brochure-button {
        @include icon(pdf, before);
        color: text-color(primary);
        background-color: bg-color(reverse);
        border: 2px solid palette(border, neutrallight);
        font-weight: 700;
        padding: 10px 95px;
        text-decoration: none;
        display: inline-block;

        &:before {
          margin-right: 15px;
          vertical-align: middle;
          font-size: rem(22);
        }
      }

      form {
        border: 2px solid border-color(neutrallight);
        display: flex;
        justify-content: space-around;
        height: 40px;
        font-family: font-family(secondary);
        font-size: rem(12);

        .mail-input {
          flex-grow: 1;
          padding: 0 15px;
        }

        .submit-mail-button {
          font-weight: 700;
          background-color: transparent;
          margin: 8px 0;
          border-left: 1px solid;
          padding: 0 25px;
        }
      }

      &:not(.editorial-brochure-button) {
        span {
          font-size: rem(10);
        }
      }
    }
  }

  &.filosofia {
    .brochure-col, .submit-mail-button {
      border-color: palette(text, filosofia);
    }

    .icon-title:before {
      color: palette(text, filosofia);
    }
  }

  &.medicina {
    .brochure-col, .submit-mail-button {
      border-color: palette(text, medicina);
    }

    .icon-title:before {
      color: palette(text, medicina);
    }
  }

  &.psicologia {
    .brochure-col, .submit-mail-button {
      border-color: palette(text, psicologia);
    }

    .icon-title:before {
      color: palette(text, psicologia);
    }
  }
}