.c-carousel {
  margin-left: -15px;
  margin-right: -15px;

  .slide {
    display: flex;
    flex-direction: column-reverse;

    .slidecard {
      padding: 30px 40px;

      h3 {
        font-size: rem(34);

        &:before {
          width: 30px;
        }
      }

      .button-primary {
        margin-top: 30px;
      }
    }
  }

  .carousel-nav, .tns-controls, .tns-item:not([id$="item0"]) {
    display: none;
  }

  .hero-carousel {
    .carousel-nav, .tns-controls, .tns-item {
      display: inline-block;
    }

    .carousel-nav {
      top: 122px;
      right: 15px;
      bottom: auto;

      .testing {
        margin-top: 10px;
      }
    }

    .tns-controls {
      top: 122px;
      right: 15px;
      bottom: auto;

      button {
        .t-unisr-homepage & {
          padding: 6px 25px;
          background-color: bg-color(reverse);

          &:first-of-type {
            padding-right: 81px;
          }
        }
      }

    }
  }
}
