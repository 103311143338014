header.main {
  position: relative;

  .header-logo {
    display: flex;
    position: relative;

    & > a {
      height: 100%;
    }
  }

  a {
    text-decoration: none;
  }

  .search-icon {
    @include icon(lens)
  }
}