.c-video-card{
  .video-card-title{
    font-size: rem(24);
    font-weight: normal;
    margin-bottom: 35px;
  }
  .video-card-image{
    width: 100%;
    height: auto;
    max-width: 100%;
  }
}