h1, .h1 {
  @each $prop, $value in map-get($typography, h1) {
    #{$prop}: $value;
  }
}

h2, .h2 {
  @each $prop, $value in map-get($typography, h2) {
    #{$prop}: $value;
  }
}

h3, .h3 {
  @each $prop, $value in map-get($typography, h3) {
    #{$prop}: $value;
  }
}

h4, .h4 {
  @each $prop, $value in map-get($typography, h4) {
    #{$prop}: $value;
  }
}

h5, .h5 {
  @each $prop, $value in map-get($typography, h5) {
    #{$prop}: $value;
  }
}

h6, .h6 {
  @each $prop, $value in map-get($typography, h6) {
    #{$prop}: $value;
  }
}

p, .paragraph {
  @each $prop, $value in map-get($typography, p) {
    #{$prop}: $value;
  }
}

a, .anchor {
  @each $prop, $value in map-get($typography, a) {
    #{$prop}: $value;
  }
}

small, .small {
  @each $prop, $value in map-get($typography, small) {
    #{$prop}: $value;
  }
}

.p-big {
   font-size: rem(14);
 }

.p-medium {
  font-size: rem(12);
}
