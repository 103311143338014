.c-media {
  padding: 0;
  margin: 0 -15px;

  .title {
    margin: 40px 0 20px;
  }

  & > .container {

    & > .row {
      & > .col-12 {
        padding: 0;
      }
    }
  }

  & + .c-text {
    margin-top: -54px;
  }

}