.bottomcard {
  padding-right: 0;
  padding-left: 0;

  figure {
    padding-left: 30px;

    h3 {
      &:before {
        width: 20px;
        left: 0;
      }
    }
  }

  picture {
    padding-top: calc(150 / 345 * 100%);
  }
}

.menucard {
  padding-right: 0;
  padding-bottom: 0;

  h3 {
    @include icon(chevron, after, false);
    justify-content: space-between;
    &:before {
      content: "";
      width: 20px;
      left: 0;
      position: absolute;
      background-color: bg-color(neutrallight);
      height: 2px;
    }
    &:after {
      float: right;
      background-color: #e6e6e6;
      height: 23px;
      width: 23px;
      text-align: center;
      font-size: 8px;
      vertical-align: middle;
      line-height: 23px;
      margin-left: 30px;
    }
  }
}

.editorialcard, .editorialcard-hp {
  .c-editorial-cards & {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 20px;

    figure {
      padding-left: 0;

      figcaption {
        flex-direction: row;
        padding: 12px 20px;

        h3 {
          font-size: rem(16);
          font-weight: 400;
          margin: 0;

          &:before {
            display: none;
          }
        }

        p {
          display: none;
        }

        .button-primary {
          font-size: 0;
          margin-top: 0;
        }
      }
    }

    picture {
      height: auto;
      padding: 0;

      img {
        display: none;
      }

      &:before, &:after {
        content: none;
      }
    }
  }
}

.c-editorial-cards {
  .editorialcard, .editorialcard-hp {
    padding-right: 0;
    padding-left: 0;
    &.col-md-3 {
      padding-bottom: 20px;
    }
    &.col-md-4 {
      padding-bottom: 5px;
    }

    figure {
      padding-left: 0;
      picture {
        display: none;
      }
      figcaption {
        flex-direction: row;
        padding: 12px 20px;

        h3 {
          font-size: rem(16);
          font-weight: 400;
          margin: 0;

          &:before {
            display: none;
          }
        }

        p {
          display: none;
        }

        .button-primary {
          font-size: 0;
          margin-top: 0;

        }
      }
    }
  }
}

.newscard {
  padding-bottom: 10px;

  &:nth-child(even) {
    padding-right: 5px;
    padding-left: 10px;
  }

  &:nth-child(odd) {
    padding-right: 10px;
    padding-left: 5px;
  }

  figcaption {
    h5 {
      font-size: rem(14);
    }

    span {
      left: 10px;

      small {
        padding-top: 10px;
      }
    }
  }

  &.newscard-cat-list {
    padding-right: 0;
    padding-bottom: 10px;
    padding-left: 0;

    figure {
      figcaption {
        padding: 16px 0;
        height: auto;

        ul {
          display: none;
        }
      }
    }

    .button-primary {
      padding-top: 0;
      margin-top: 0;
    }
  }
}

.c-governance-cards {
  .container {
    .governance-card {
      padding: 0;

      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }
}

.coursecard {
  margin-bottom: 15px;
  a {
    .course-info {
      &:last-child {
        padding: 15px 0;
      }
    }
    .extra-info {
      p {
        span {
          margin-right: 20px;
        }
      }
    }
  }
}