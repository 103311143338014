.t-unisr-news-listing {
  .main-content {
    position: relative;
    .filter.row {
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
      margin: 0;
      //height: 102px;
      display: flex;
      align-items: center;
      background: bg-color(reverse);
      .col-6 {
        padding: 0 10px;
      }
      .handler-accordion {
        height: 62px;
        align-items: center;
        display: flex;
        border: 1px solid border-color(neutrallight);
        font-family: font-family(secondary);
        font-size: rem(16);
        font-weight: 500;
        color: text-color(neutralmedium);
        &:after {
          top: 50%; //NOTE TODO kinda fast solution, find a better way to do this
          transform-origin: top;
          color: text-color(neutraldark);
        }
      }
      .target-accordion {
        position: absolute;
        left: 10px;
        right: 10px;
        z-index: $targetfilters;
        max-height: 250px;
        overflow-y: auto;
      }
    }
    .listing-news {
      padding-top: 102px;
    }
  }
  main {
    .container {
      padding-right: 10px;
      padding-left: 10px;
      .row {
        margin-right: -10px;
        margin-left: -10px;
      }
    }
  }
  .listing-news {
    margin: 0 -30px;
    .newscard {
      padding-right: 10px;
      padding-left: 10px;
      figcaption {
        h5 {
          margin-bottom: 0;
        }
      }
    }
  }
}

.c-grid-news {
  .listing-news {
    margin: 0 -30px;
  }
}