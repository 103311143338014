.c-toc {
  .anchor-box {
    display: flex;
    justify-content: space-around;
    background-color: bg-color(reverse);
    padding: 10px 15px;
    border-top: solid 1px #e1e1e1;

    a {
      font-size: rem(14);
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      text-decoration: none;
    }
  }
}