.c-numbers {
  text-align: left;
  padding: 30px 25px;
  margin-right: -15px;
  margin-left: -15px;

  .c-numbers-item {

    span {
      font-size: rem(24);
    }
  }
  .c-numbers-item-label {
    font-size: rem(10);
    padding: 0;
  }
  .c-numbers-col-caption {
    border-top: 1px solid;
    padding: 0;
    .c-numbers-caption {
      text-align: center;
    }
  }
  .col-4 {
    padding: 0;
  }
}