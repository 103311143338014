.news-controls {
  background: bg-color(primary);
  color: palette(text, reverse);
  .row {
    padding-top: 50px;
    padding-bottom: 60px;
    border-bottom: 1px solid border-color(neutralmidlight);
  }

  .col-6 {
    position: relative;

  }
  span {
    display: block;
    font-size: rem(12);
    font-weight: 700;
  }

  .next {
    text-align: right;
    span {
      @include icon(chevron, after);
      font-size: rem(14);
      &:after {
        margin-left: 10px;
      }
    }
  }

  .prev {
    span {
      @include icon(chevron, before);
      font-size: rem(14);
      &:before {
        transform: rotate(180deg);
        margin-right: 10px;
      }
    }
  }

  p {
    margin: 0;
    font-family: font-family(secondary);
    font-size: rem(12);

  }

  a {
    display: block;
    text-decoration: none;
    position: static
  }

  .prev {
    span:before {

      background-color: bg-color(reverse);
      width: 40px;
      height: 40px;
      text-align: center;
      padding: 0;
      color: palette(text, neutraldark);
      display: block;
      text-decoration: none;
      font-size: rem(14);
      font-weight: 700;
      line-height: 40px;
      display: inline-block;
      vertical-align: middle;
    }
    p {
      padding-left: 50px;
    }
  }

  .next {
    span:after {
      background-color: bg-color(reverse);
      width: 40px;
      height: 40px;
      text-align: center;
      padding: 0;
      color: palette(text, neutraldark);
      display: block;
      text-decoration: none;
      font-size: rem(14);
      font-weight: 700;
      line-height: 40px;
      display: inline-block;
      vertical-align: middle;
    }
    p {
      padding-right: 50px;
    }
  }

}