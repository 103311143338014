.t-unisr-offerta-formativa {
  .filtercourse {
    &.collapsed {
      display: none;
    }
    position: fixed;
    top: 0;
    background: white;
    height: 100vh;
    z-index: 1;
    overflow: auto;
    .removefilter {
      &.hide-mobile {
        display: none;
      }
    }
    .mobile-filter-close {
      @include icon(close, after, false);
      background-color: bg-color(neutrallight);
      position: absolute;
      top: 0;
      right: 0;
      width: 42px;
      height: 42px;
      padding: 0;
      display: flex;
      justify-content: center;
    }
    .left-nav {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .filter-utils {
      //position: sticky;
      font-family: font-family(secondary);
      font-size: rem(12);
      font-weight: 700;
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      width: 100%;
      height: 43px;
      min-height: 43px;
      border-top: 2px solid border-color(neutrallight);
      margin: 6px 0 0;
      .removefilter {
        margin-bottom: 0;
        flex-basis: 50%;
      }
      .show-results {
        font-family: font-family(secondary);
        font-weight: 700;
        border: none;
        background-color: bg-color(neutrallight);
        flex-grow: 1;
      }
    }
    h3 {
      font-size: rem(16);
    }
    .filtercoursebox {
      padding: 0;
      flex-grow: 1;
      margin-bottom: 50px;
      h6 {

      }
      div {
        &[data-group] {

        }
        a {

          &:last-child {

          }
          &:not(.slider) {
            &.selected {

              &:after {

              }
            }
          }
          &.unavailable {

          }
        }
      }
    }
    .registration {

      & > span {

      }
      &.switch {
        input {

        }
      }
      .slider {

        &.selected {

          &:after {

          }
        }
        .round {
          &:before {

          }
        }

        &:after {

        }
      }
      input:checked + .slider {

      }

      input:focus + .slider {

      }

      input:checked + .slider:before {

      }

      .slider.round {

      }

      .slider.round:before {

      }
    }
    .removefilter {

      &:after {

      }
    }
  }

  .resultsnumber {
    margin-bottom: 8px;
  }

  .filter-header-utils {
    display: flex;
    margin: 0 -15px 20px;
    height: 44px;
    .resultsnumber {
      font-size: rem(14);
      font-weight: 300;
      background-color: bg-color(reverse);
      display: flex;
      align-items: center;
      flex-basis: 40%;
      padding: 0 15px;
      margin: 0;
      .resultsnumberspan {
        margin-right: 5px;
      }
    }
    .mobile-filter-open {
      background-color: bg-color(neutrallight);
      display: flex;
      align-items: center;
      flex-basis: 60%;
      padding: 0 30px 0 15px;
      border: none;
      &:after {
        position: absolute;
        right: 15px;
      }
    }
  }
}