.c-research-banner, .c-text-image {
  &.reverse {
    .row {
      flex-direction: row-reverse;
    }

    .col-md-5 {
      padding-right: 15px;
      padding-left: 0;
    }

    .picture-col {
      padding-right: 15px;
      padding-left: 0;
    }
  }
}