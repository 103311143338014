.info-teacher {
  padding: 25px 40px;
  background: bg-color(reverse);
  div.collapsed {
    display: none;
  }
  .regulations {
    margin-top: 50px;
    font-size: rem(11);
  }
}