/**
 * Ref: https://www.smashingmagazine.com/2014/06/sassy-z-index-management-for-complex-layouts/
 * In order to maintain the stacking order of the elements,
 * we can set up a Sass list which represents the order in which we want our elements to appear,
 * from lowest to highest, like shown below.
 * (Note that if we want to add an element to the existing stacking order,
 * we just have to update the list with our new element)
 * In order to retrieve the z-index value to assign to each element,
 * you can use the index() sass function, for i.e. .modal {z-index: index($elements, modal)}
 */

$elements: carouselcontrols, targetfilters, numbers-zindex, header, logo, navigation, utilityheader, backtotop, backdrop, static-map;

//## Playing with system wide z-indexes
$carouselcontrols        : index($elements, carouselcontrols);
$targetfilters           : index($elements, targetfilters);
$numbers-zindex			     : index($elements, numbers-zindex);
$header-zindex           : index($elements, header);
$logo-zindex             : index($elements, logo);
$navigation-zindex       : index($elements, navigation);
$utilityheader-zindex    : index($elements, utilityheader);
$backtotop-zindex		     : index($elements, backtotop);
$backdrop-zindex         : index($elements, backdrop);
$static-map-zindex       : index($elements, static-map);


//  $modal-elements: fields, form-controls, errors, autocomplete-dropdown;

/**
 * To make to solution scalable across the website, you can add multiple lists, related to specific contexts.
 * Remember that CSS spec informs us that
 * “stacking contexts are generated by any positioned element
 * (including relatively positioned elements) having a computed value of ‘z-index’ other than ‘auto’”,
 * so stick to this spec if you want to create a new stacking context.
 */
