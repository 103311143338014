.tabs-navigation {
  margin: 0 0 5px;

  &:not(.stick) {
    .container {
      padding: 0;
    }
  }

  .marginer {
    height: 75px;
  }

  ul {
    background: bg-color(neutral);
    display: block;
    white-space: nowrap;
    overflow-x: scroll;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  li {
    display: inline-block;
    width: auto;
    background-color: bg-color(reverse);
    margin: 0 15px;
  }

  a {
    display: block;
    padding: 10px 0;
  }

  .title {
    @include icon(plus_accordion, after, false);
    font-family: font-family(secondary);
    font-weight: 700;
    padding: 15px 45px 15px 15px;
    background-color: tabs(default, background-color);
    border-bottom: 2px solid;

    &:after {
      right: 15px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      color: text-color(reverse);
      background-color: bg-color(neutraldark);

      .filosofia & {
        background-color: $filosofia;
      }

      .medicina & {
        background-color: $medicina;
      }

      .psicologia & {
        background-color: $psicologia;
      }
    }

    .filosofia & {
      border-color: palette(border, filosofia);
    }

    .medicina & {
      border-color: palette(border, medicina);
    }

    .psicologia & {
      border-color: palette(border, psicologia);
    }
  }

  .tabs {
    text-decoration: none;
    color: $black;
    padding-right: 15px;
    padding-left: 15px;
  }
}