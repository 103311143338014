.t-unisr-internal {
  &:not(.not-overlay) {

    .main-content {
      .widget.c-text {
        &:first-child {
          margin-top: -96px;
        }
      }
    }
  }
  &.not-overlay {
    .main-content {
      margin-top: 0;
    }
  }
}
