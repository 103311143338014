.cookie-policy-container {
  background-color: bg-color(neutralverydark);
  z-index: $backdrop-zindex;
  transition: bottom .6s;
  position: fixed;
  bottom: 0;
  height: 107px;
  width: 100%;
  color: text-color(reverse);

  &.accepted {
    bottom: -107px;
  }

  .container {
    &, .row {
      height: 100%;
    }
  }

  .cookie-policy-text {
    p {
      color: white;

      a {
        color: text-color(medicina);
      }
    }
  }

  .button-accept-cookie {
    color: white;
    padding: 10px 12px;
    display: inline-block;
    text-decoration: none;
    background-color: bg-color(primary);
  }
}