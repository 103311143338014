.tns-controls {
  top: 50%;

  button {
    .t-unisr-homepage & {
      &:before {
        font-size: rem(26);
      }
    }
  }

  .t-unisr-homepage & {
    top: calc(50% - 30px);
  }
}