.tabs-titles {
  .tab-title {
    display: inline-block;
    font-weight: 300;
    padding: 10px 12px;
    margin-right: 5px;
    color: tabs(default, active-color);
    background-color: tabs(default, active-background-color);
    margin-bottom: 5px;
    font-size: rem(15);

    &.collapsed {
      color: #4c4b4e;
      background-color: #eee;
      cursor: pointer;

      &:hover {
        color: tabs(default, hover-color);
        background-color: tabs(default, hover-background-color);
        box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.07);
      }
    }
  }
}

.tab-title {
  &.visible-xs {
    display: none;
  }
}
