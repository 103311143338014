.c-profile-cards {
  margin-right: -15px;
  margin-left: -15px;

  > .container {
    box-shadow: none;

    > .row {
      flex-direction: column;
      align-items: center;
    }
  }

  .profile-card-item {
    &:not(:first-child) {
      margin-top: 40px;

      .profile-card-item__title {
        width: calc(100vw - 40px);
        height: 75px;
        margin-left: 50%;
        line-height: 55px;
        background-color: $neutral;
        transform: translateX(-50%);
      }

      .profile-card-item__background {
        margin-top: -26px;
        position: relative;
      }
    }
  }

  &.rounded-images {
    > .container {
      > .row {
        flex-direction: row;
      }
    }

    .profile-card-item {
      &:not(:first-child) {
        .profile-card-item__title {
          background-color: $transparent;
          height: auto;
          line-height: normal;
        }
      }

      &__background {
        width: 100%;
        margin-top: 0;
      }
    }
  }

  .carousel-wrapper {
    padding-right: 15px;
    margin-bottom: 50px;

    .tns-nav {
      width: 100%;

      button:first-child {
        margin-left: 20px; //NOTE this is a fast solution to align nav dots to the center, TODO fix it the proper way changing the v_tinyslider scss file
      }
    }
  }
}