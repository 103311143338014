.c-imgtext-card {
  .card-img {
    margin-bottom: 15px;

    figure {
      position: relative;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
  }

  h5 {
    font-size: rem(18);
    margin-top: 0;
    margin-bottom: 15px;
  }

  .card-description {
    font-size: rem(12);

    ul {
      padding-left: 0;
      list-style-position: inside;
    }

    strong {
      font-weight: bold;
    }

    a {
      text-decoration: none;
    }

    &.align-justified {
      text-align: justify;
    }

    &.align-right {
      text-align: right;
    }

    &.align-left {
      text-align: left;
    }
  }
}