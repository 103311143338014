.c-why-unisr {
  overflow: hidden;

  .handler-accordion {
    display: flex;
    align-items: center;
    border: 0;
    margin: 5px 0;
    padding: 0 20px;
    width: 100%;
    background-color: bg-color(reverse);
    cursor: pointer;

    &:after {
      color: text-color(primary);
      background-color: bg-color(neutrallight);
      height: 30px;
      width: 30px;
      text-align: center;
      line-height: 30px;
      padding-left: 1px;
      font-size: 1rem;
      position: absolute;
      right: 20px;
    }
  }

  .target-accordion {
    position: relative;
    top: -5px;
  }
}

.c-why-unisr--hp {
  background-color: bg-color(reverse);
  margin-right: auto;
  margin-left: auto;
}

.c-why-unisr__content--hp {
  padding: 15px 20px;
  margin: 15px;

  img {
    max-width: 50px;
  }
}

.c-why-unisr__title--hp {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  font-size: rem(12);
  line-height: 16px;
}

.c-why-unisr__icon {
  max-width: 40px;
}

.c-why-unisr__title {
  max-width: 200px;
  text-align: left;
  margin: 20px;
  font-weight: 700;
  font-size: rem(14);
  line-height: 20px;
}

.c-why-unisr__figure {
  img {
    width: 100%;
  }
}

.c-why-unisr__description {
  position: absolute;
  z-index: 22;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  backdrop-filter: blur(20px);
  font-size: 14px;
  line-height: 16px;
}

.c-why-unisr__button--hp {
  margin: 35px auto;

  a {
    width: calc(100% - 18px);
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    font-size: rem(14);
    line-height: rem(20);
    font-weight: 600;
  }

}
