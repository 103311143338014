.card {
  &.teacher {
    margin-top: -150px;
  }
}

.menucard {
  min-height: 325px;
}

.bottomcard {
  margin-top: -70px;
}

.c-governance-cards {
  padding-top: 10px;

  .container {
    padding: 0;
  }
}

/* FIXES FOR IE 11 */
.slidecard, .facultycard {
  h3 {
    position: relative;

    &:before {
      top: 15px;
      left: -70px;
    }
  }
}

.bottomcard figure h3:before, .editorialcard figure h3:before, .editorialcard-hp figure h3:before, .facultycard figure h3:before, .slidecard figure h3:before {
  top: 15px;
  left: -70px;
}

.bottomcard figure h3, .editorialcard figure h3, .editorialcard-hp figure h3, .facultycard figure h3, .slidecard figure h3 {
  position: relative;
}

.newscard.newscard-cat-list figure > div .dots {
  align-items: center;
  height: 100%
}

.newscard.newscard-cat-list figure > div h2 {
  top: 50%;
  transform: translateY(-50%);
}

body {
  .c-research-banner .description h2 {
    position: relative;

  }

  .c-research-banner .description h2:before, .c-text-image .description h2:before {
    top: 20px;
    left: -70px;
  }
}

.title-banner {
  height: 1px;
}


.coursecard {
  h5 {
    &:first-child {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}