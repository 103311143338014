.title-banner {
  .t-unisr-course &, .t-unisr-internal.not-overlay.with-course-box & {
    min-height: 445px;
    padding-bottom: 81px;
  }

  .t-unisr-section-home &, .t-unisr-tabs &, .t-unisr-course-tabs &, .t-unisr-internal.not-overlay & {
    min-height: 320px;
  }

  .t-unisr-internal:not(.not-overlay) & {
    min-height: 320px;
    padding-bottom: 66px;

    .text-center {
      h1 {
        padding: 0 17%;
        margin: 0;
      }
    }
  }

  .t-unisr-docente & {
    align-items: flex-end;

    .col-12 {
      padding-left: 20px;
    }
  }

  .t-unisr-search-results & {
    min-height: 200px;

    h1 {
      margin: 0;
    }
  }

  .t-unisr-news-listing & {
    min-height: 240px;
  }

}