.c-editorial-cards {
  &.with-bg {
    h2, h6, .editorialcard:first-child picture {
      &:before, &:after {
        content: "";
        background-color: bg-color(reverse);
        position: absolute;
        vertical-align: middle;
        width: calc(100% + 30px);
        top: 0;
        height: 100%;
        z-index: -1;
      }

      &:before {
        left: -15px;
      }

      &:after {
        right: 8px;
      }
    }

    .editorialcard.col-md-3 {
      picture {
        &:before {
          content: "";
          background-color: bg-color(reverse);
          position: absolute;
          vertical-align: middle;
          width: calc(100% + 30px);
          top: 0;
          height: 100%;
          z-index: -1;
          left: -15px;
        }
      }
    }

    h2 {
      position: relative;
      padding: 0 0 20px;
      margin: 0;
    }

    h6 {
      position: relative;
      margin: 0;
      padding: 38px 0 0;
    }

    .editorialcard {
      h2, h6 {
        z-index: 1;
      }
      picture {
        height: auto;
        padding-top: 0;
        position: relative;
        z-index: 1;
        overflow: visible;
        flex-shrink: 0;

        img {
          top: auto;
          left: auto;
          position: relative; //NOTE test in progress
        }
      }
    }

    .editorialcard-hp {
      picture {
        height: auto;
        padding-top: 0;
        position: relative;
        overflow: visible;
        flex-shrink: 0;
        background-color: black;

        img {
          opacity: 0.65;
          top: auto;
          left: auto;
          position: relative; //NOTE test in progress
        }
      }

      figcaption {
        visibility: hidden;
        padding: 0;
        max-height: 0;

        h3 {
          visibility: visible;
          position: absolute;
          margin: 0;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: text-color(reverse);
          text-transform: uppercase;
          font-size: rem(28);
          font-weight: 900;
          text-align: center;
          line-height: 28px;
          &:before {
            display: none;
          }
        }

        span {
          display: none;
        }
      }
    }
  }
}