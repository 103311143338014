.c-title {
  h2,
  h3 {
    &.wrapline {
      display: table;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      &:before, &:after {
        border-top: 1px solid;
        border-color: palette(border,brand);
        content: '';
        display: table-cell;
        position: relative;
        transform: translateY(50%);
        width: 45%;
      }
      &:before { right: 1.5%; }
      &:after { left: 1.5%; }
      //lines alway of faculty color
      .medicina & {
        &:before, &:after{
          border-color: text-color(medicinadark);
        }
      }
      .filosofia & {
        &:before, &:after{
          border-color: text-color(filosofia);
        }
      }
      .psicologia & {
        &:before, &:after{
          border-color: text-color(psicologiadark);
        }
      }
    }
  }
}