.t-unisr-news {
  .title-banner {
    padding-bottom: 40px;

    .container {
      height: 100%;

      .row {
        height: 100%;

        .wrapper-banner {
          margin-left: 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          h1 {
            font-size: rem(22);
            line-height: 24px;
            padding: 0 15px;
            margin: 20px 0 0 0;
          }

          h3 {
            font-size: rem(16);
            padding: 0 15px;
          }

          .news-calendar {
            padding: 0 15px;
            font-size: rem(14);
          }

          a {
            margin: -10px 0 20px -15px;
            position: relative;

            &:before {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .news-content-row {
    & > .col-md-5 {
      padding: 0 15px;

      .category {
        left: 30px;
      }
    }

    .wrapper-news {
      .c-media {
        padding: 44px 15px 0;

        .col-12 {
          padding: 0 15px;
        }
      }

      .c-text {
        .col-12 {
          padding: 0 30px;
        }
      }
    }
  }

  .c-latest-news {
    &.widget {
      padding-bottom: 80px;
    }
  }

  .category-col {
    background-color: bg-color(neutrallight);

    .category {
      padding: 24px 0;
      border-bottom: 1px solid border-color(neutralmedium);
      border-right-width: 0;
      border-left-width: 0;
      border-top-width: 0;
      background-color: bg-color(neutrallight);
      width: 100%;
      font-family: font-family(secondary);
      font-weight: 700;
      font-size: rem(19);
      display: block;

      &:after {
        right: 0;
      }
    }
  }
}