.news-carousel {
  figure {
    width: 310px;

    .news-image  {
      height: 160px;
    }

    figcaption {
      height: 180px;

      h5 {
        margin: 0;
      }
    }
  }
}