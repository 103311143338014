.c-sevices-box {
  margin-bottom: 15px;
  padding-left: 0;
  padding-right: 0;

  &:first-of-type {
    margin-top: 20px;
  }
}
.c-sevices-box__content {
  display: flex;
  align-items: center;
  padding: 15px;
  text-align: left;
}

.c-sevices-box-icon {
  height: 66px;
  width: 66px;
  margin: 0 20px 0 0;
}

.c-sevices-box-icon__image {
  height: 34px;
  width: 34px;
}
