.c-boxes {
  &.mobile-only {
    display: block;
  }
  .left-nav & {
    display: none;
  }
  .col-12 {
    padding: 0;
  }

  &.widget {
    .links {
      a {
        margin-bottom: 5px;
      }
    }
  }
}

.c-course-boxes {
  position: relative;
  padding-bottom: 40px;

  &.filosofia {
    a:not(.course-boxes-link):after {
      background-color: bg-color(filosofia);
      color: text-color(reverse);
    }
  }

  &.medicina {
    a:not(.course-boxes-link):after {
      background-color: bg-color(medicina);
      color: text-color(reverse);
    }
  }

  &.psicologia {
    a:not(.course-boxes-link):after {
      background-color: bg-color(psicologia);
      color: text-color(reverse);
    }
  }

  .row {

    .col-md-4 {
      position: static;
    }
  }

  h2 {
    margin: 0 0 15px;

    &:not(.global) {
      font-size: rem(24);
      padding: 0;
    }
  }

  .button-primary {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    padding-left: 35px;
    padding-right: 50px;
    justify-content: space-between;
    padding-left: 35px;

    &:after {
      color: white;
      position: absolute;
      right: 20px;
    }
  }

  .links {
    a {
      font-size: 16px;
      line-height: rem(16);
      padding: 20px 60px 20px 35px;
      margin: 5px 0;

      &:before {
        width: 20px;
      }

      &:after {
        right: 20px;
      }
    }

    .tooltip {
      display: none;
    }
  }

  .col-md-3, .col-md-9 {
    padding: 0 10px;
  }
}

.c-info-boxes {
  &.widget {
    &:not(:last-child) {
      margin-bottom: 40px;
    }

    &:first-of-type {
      margin-top: -36px;
    }
  }

  .links {
    &.col-md-4 {
      margin-bottom: 0;
    }

    padding: 0 10px;

    .course-boxes-link {
      font-size: rem(16);
      padding: 8px 40px 8px 30px;
      margin: 5px 0;
      min-height: 54px;

      &:before {
        width: 20px;
      }

      &:after {
        right: 10px;
      }
    }
  }

  .list {
    padding: 0;
    margin-top: 10px;
    margin-bottom: 0;

    .inner {
      h6 {
        padding-left: 10px;
      }

      .links {
        a {
          padding: 10px 40px 10px 10px;
          min-height: 54px;

          &:after {
            right: 10px;
          }
        }
      }
    }
  }

  &:first-child {
    margin-top: 30px;
  }

  .no-gutters {
    margin: 0 -15px;

    .list {
      margin-top: 0;

      .inner {
        padding: 0;
      }

      &:first-child {
        .inner {
          padding-top: 12px;
        }
      }

      &:last-child {
        .inner {
          padding-bottom: 12px;
        }
      }
    }
  }
}