@font-face {
  font-family: "unisr";
  font-display: swap;
  src: local('unisr'),
       url('../fonts/unisr.woff2') format('woff2'),
       url('../fonts/unisr.woff') format('woff'),
       url('../fonts/unisr.ttf') format('truetype'),
       url('../fonts/unisr.eot') format('eot'),
       url('../fonts/unisr.svg#unisr') format('svg');
}

$iconNameList: access, arrow, atom, calendar, chain, chat_icon, chevron, clock, close, credits, dna, doc, download, external, facebook, faq, filosofia_icon, filter, flask, flickr, googleplus, hamburger, home, instagram, interfacolta_icon, language, lens, linkedin, mail, medicina_icon, meeting_icon, meters, miniheader_back, minus_accordion, pdf, phone, pin, plus_accordion, psicologia_icon, quote-open, researcher, researchgate, share_email, skype, slideshare, student, syllabus, tiktok, time, tumblr, twitter, unisr_plus, website, xls, youtube;

@mixin icon-styles {
  display: inline-block;
  font-family: "unisr";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%icon {
  @include icon-styles;
}

@function icon-char($filename) {
  $char: "";
 
  @if $filename == access {
    $char: "\E001";
  } 
  @if $filename == arrow {
    $char: "\E002";
  } 
  @if $filename == atom {
    $char: "\E003";
  } 
  @if $filename == calendar {
    $char: "\E004";
  } 
  @if $filename == chain {
    $char: "\E005";
  } 
  @if $filename == chat_icon {
    $char: "\E006";
  } 
  @if $filename == chevron {
    $char: "\E007";
  } 
  @if $filename == clock {
    $char: "\E008";
  } 
  @if $filename == close {
    $char: "\E009";
  } 
  @if $filename == credits {
    $char: "\E00A";
  } 
  @if $filename == dna {
    $char: "\E00B";
  } 
  @if $filename == doc {
    $char: "\E00C";
  } 
  @if $filename == download {
    $char: "\E00D";
  } 
  @if $filename == external {
    $char: "\E00E";
  } 
  @if $filename == facebook {
    $char: "\E00F";
  } 
  @if $filename == faq {
    $char: "\E010";
  } 
  @if $filename == filosofia_icon {
    $char: "\E011";
  } 
  @if $filename == filter {
    $char: "\E012";
  } 
  @if $filename == flask {
    $char: "\E013";
  } 
  @if $filename == flickr {
    $char: "\E014";
  } 
  @if $filename == googleplus {
    $char: "\E015";
  } 
  @if $filename == hamburger {
    $char: "\E016";
  } 
  @if $filename == home {
    $char: "\E017";
  } 
  @if $filename == instagram {
    $char: "\E018";
  } 
  @if $filename == interfacolta_icon {
    $char: "\E019";
  } 
  @if $filename == language {
    $char: "\E01A";
  } 
  @if $filename == lens {
    $char: "\E01B";
  } 
  @if $filename == linkedin {
    $char: "\E01C";
  } 
  @if $filename == mail {
    $char: "\E01D";
  } 
  @if $filename == medicina_icon {
    $char: "\E01E";
  } 
  @if $filename == meeting_icon {
    $char: "\E01F";
  } 
  @if $filename == meters {
    $char: "\E020";
  } 
  @if $filename == miniheader_back {
    $char: "\E021";
  } 
  @if $filename == minus_accordion {
    $char: "\E022";
  } 
  @if $filename == pdf {
    $char: "\E023";
  } 
  @if $filename == phone {
    $char: "\E024";
  } 
  @if $filename == pin {
    $char: "\E025";
  } 
  @if $filename == plus_accordion {
    $char: "\E026";
  } 
  @if $filename == psicologia_icon {
    $char: "\E027";
  } 
  @if $filename == quote-open {
    $char: "\E028";
  } 
  @if $filename == researcher {
    $char: "\E029";
  } 
  @if $filename == researchgate {
    $char: "\E02A";
  } 
  @if $filename == share_email {
    $char: "\E02B";
  } 
  @if $filename == skype {
    $char: "\E02C";
  } 
  @if $filename == slideshare {
    $char: "\E02D";
  } 
  @if $filename == student {
    $char: "\E02E";
  } 
  @if $filename == syllabus {
    $char: "\E02F";
  } 
  @if $filename == tiktok {
    $char: "\E030";
  } 
  @if $filename == time {
    $char: "\E031";
  } 
  @if $filename == tumblr {
    $char: "\E032";
  } 
  @if $filename == twitter {
    $char: "\E033";
  } 
  @if $filename == unisr_plus {
    $char: "\E034";
  } 
  @if $filename == website {
    $char: "\E035";
  } 
  @if $filename == xls {
    $char: "\E036";
  } 
  @if $filename == youtube {
    $char: "\E037";
  } 

  @return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
  &:#{$insert} {
    @if $extend {
      @extend %icon;
    } @else {
      @include icon-styles;
    }
    content: icon-char($filename);
    @content
  }
}

@mixin icon-content($filename) {
  @extend %icon;
  content: icon-char($filename);
}

@mixin icons-classes($iconList: $iconNameList, $insert: before, $extend: true) {
  @each $iconName in $iconList {
    .icon-#{$iconName} {
      @include icon(#{$iconName}, $insert, $extend);
    }
  }
}