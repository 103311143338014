.c-albo {
  padding: 40px 60px 40px;
  background-color: bg-color(reverse);

  .col-12 {
    position: static;
  }

  a {
    text-decoration: none;
  }

  .document-download {
    border: none;
    margin: 0 9px;
    padding-top: 20px;
    color: text-color(primary);

    &:not(:first-of-type) {
      border-top: 1px solid border-color(neutrallight);
    }

    &.document-expired {
      background-color: #fee605 !important;

      a {
        background-color: #fee605 !important;
      }
    }

    .document-caption {
      font-family: font-family(secondary);
      position: relative;
      padding-left: 54px;

      &:before {
        content: "";
        width: 46px;
        position: absolute;
        height: 1px;
        background-color: bg-color(neutrallight);
        bottom: 0;
        left: -9px;
      }
    }

    &:first-of-type {
      padding-top: 10px;
      margin-top: 0;
    }
  }

  .button-download {
    padding-left: 54px;

    &:after {
      right: 3px;
    }
  }

  .document-caption {
    font-family: font-family(secondary);
    position: relative;
    padding-left: 54px;

    &:before {
      content: "";
      width: 46px;
      position: absolute;
      height: 1px;
      background-color: bg-color(neutrallight);
      bottom: 0;
      left: -9px;
    }
  }
}