blockquote {
  font-weight: 500;
  color: palette(text, neutraldark);
  margin: 1em;
  padding: 0 4%;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 1%;
    //width: 4px;
    //background-color: bg-color(primary);
    top: -5px;
    bottom: -5px;
  }

  .c-text & {
    @include icon('quote-open', before);
    @include icon('quote-open', after);
    margin: 0;
    margin-bottom: 20px;
    padding: 24px 40px;
    //background-color: bg-color(neutrallight);
    //border-left: 3px solid border-color(filosofia);
    p {
      font-family: font-family(secondary);
      font-weight: 500;
    }

    &:before {
      color: text-color(medicinalight);
      font-size: rem(24);
      position: absolute;
      left: 0;
      top: 0;
    }

    &:after {
      color: text-color(medicinalight);
      font-size: rem(24);
      position: absolute;
      right: 0;
      bottom: 0;
      transform: rotate(180deg);
    }

    p {
      margin: 0;
    }
  }

  cite {
    font-style: normal;
    font-size: rem(12);
  }
}