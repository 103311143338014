.widget {

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &.c-text {
    & + .c-media {
      margin-top: -50px;
    }
  }
}