/* ===NORMALIZE STYLE=== */
@include normalize();

html {
  box-sizing: border-box;
  scroll-behavior: smooth; /* Supported by firefox and Chrome only. Use a fallback for unsupported browsers, if you need. */
  font-size: $font-size-percentage-root;

  *,
  *:before,
  *:after {
    /**
     * Use inheritance here in order to make easier
     * to change the box-sizing in plugins or other components that leverage other behavior.
     * Credits: Jonathan Neal http://blog.teamtreehouse.com/box-sizing-secret-simple-css-layouts#comment-50223
     */
    box-sizing: inherit;
  }

  &.overflow-hidden {
    overflow-y: hidden;

    body {
      overflow: hidden;
    }
  }
}

body {
  @include font(primary, 300);
  font-size: rem($font-size-rem-base);
  color: text-color(primary);
  line-height: $line-height;
  text-rendering: optimizeLegibility;
  letter-spacing: .3px;
}

main.main {
  background-color: bg-color(neutral);
  .t-unisr-homepage & {
    overflow-x: hidden;
  }
}

img {
  max-width: 100%;
}

figure {
  margin: 0;
}

//Remove Safari/Chrome textinput/textarea glow
textarea, select, input, button {
  outline: none;
}

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}