.c-editorial-cards {
  .container {
    padding: 15px;
  }

  .editorialcard-hp {
    &.col-md-3 {
      padding-bottom: 5px;
    }

    figure {
      figcaption {
        h3 {
          font-size: rem(14);
          font-weight: 600;
          line-height: rem(20);
        }
      }
    }
  }

  &.with-bg {
    & > h2 {
      margin-top: 0;
    }
  }
}