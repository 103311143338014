.t-unisr-organizzazione-governance {
  .main-content {
    .c-title {
      height: 74px;

      &,
      .boxed {
        background-color: bg-color(neutral);
        font-size: rem(20);
        padding-top: 6px;
      }

      &.bg-light {
        background-color: bg-color(reverse);
      }
    }

    .c-two-columns {
      > .container {
        padding: 0;

        > .row {
          margin-top: -32px;

          > .col-md-6 {
            background-color: bg-color(reverse);
          }
        }
      }

      .c-text {
        .container {
          padding: 0;
        }

        h2, h3, h4 {
          &:before {
            content: none;
          }
        }
      }

      .left-col {
        .c-text {
          padding-right: 27px;
          padding-left: 110px;
        }
      }

      .right-col {
        .c-text {
          padding-right: 110px;
          padding-left: 27px;
        }
      }
    }
  }
}