.c-profile-cards {
  border-top: 75px solid $neutral;

  .profile-card-item {
    margin-top: -80px;
    border-radius: .25rem;

    &__title {
      text-align: center;
      margin-bottom: 8px;
      font-size: rem(20);
      margin-top: 10px;
      font-weight: normal;
    }

    &__background {
      border-radius: .25rem;
      background-color: bg-color(reverse);

      picture {
        padding-top: 100%;
      }

      figcaption {
        padding: 0;
      }
    }

    &__photo {
      border: 32px solid $white;
      border-radius: .25rem;
    }

    &__name {
      padding: 0 32px 30px;
      font-weight: normal;
      position: relative;
      text-align: center;
      background: white;
      font-size: rem(11);
      z-index: $carouselcontrols;
      margin: 0;
      margin-top: -20px;
      border-radius: .25rem;

      a {
        .psicologia &, .medicina &, .filosofia & {
          color: text-color(primary);
        }

        color: text-color(primary);
        text-decoration: none;
      }
    }

    .profile-card-item__background {
      .profile-card-item__info, .profile-card-item__name {
        a {
          color: text-color(medicina) !important;

          &:hover {
            color: text-color(medicinadark) !important;
          }
        }

      }
    }
  }

  &.rounded-images {
    border-top: none;

    .profile-card-item {
      margin-top: 0;
      padding-bottom: 0;

      &__background {
        box-shadow: none;
        padding-top: 30px;
        padding-bottom: 30px;
      }

      &__photo {
        border: none;

        img {
          transform: translateX(-50%);
          border-radius: 100%;
          height: 160px;
          width: 160px;
          border: none;
          left: 50%;
        }
      }

      figure {
        background: bg-color(transparent);
        box-shadow: none;

        picture {
          padding-top: 160px;
        }
      }

      &__title,
      &__name,
      &__info {
        font-family: font-family(secondary);

        a {
          color: text-color(primary);
          text-decoration: none;
        }
      }

      &__title {
        font-weight: bold;
        font-size: rem(14);
      }

      &__name {
        font-size: rem(16);
        margin: 0;
        padding: 0 20px 15px;
        background: bg-color(transparent);
      }

      &__info {
        text-align: center;

        p {
          font-size: rem(16);

          strong {
            font-weight: bold;
          }
        }
      }
    }
  }

  .carousel-wrapper {
    width: 100%;
  }
}