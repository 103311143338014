.t-unisr-offerta-formativa {
  .filtercourse {
    h3 {
      display: inline-block;
      margin-top: 6px;
      margin-bottom: 6px;
    }

    .filter-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .removefilter {
        font-family: font-family(secondary);
        min-height: 32px;
        padding-right: 36px;
        padding-left: 16px;
      }
    }

    .filtercoursebox {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      background-color: bg-color(reverse);
      padding: 20px 30px;

      h6 {
        margin-top: 0;
        margin-bottom: 8px;
      }

      div {
        &[data-group] {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          margin-bottom: 5px;
        }

        button {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-grow: 1;
          flex-basis: calc(50% - 10px);
          font-size: 12px;
          font-family: font-family(secondary);
          line-height: 1.618;
          text-align: center;
          text-decoration: none;
          border: 1px solid border-color(neutrallight);
          padding: 6px;
          margin-right: 10px;
          margin-bottom: 10px;
          background-color: bg-color(reverse);
          _:-ms-fullscreen, :root & {
            flex-basis: 41%;
          }
          &:last-child {
            flex-grow: initial;
          }

          &:not(.slider) {
            &.selected {
              background-color: bg-color(neutrallight);
              @include icon(close, after, false);
              position: relative;
              padding-right: 26px;

              &:after {
                position: absolute;
                right: 10px;
              }
            }
          }

          &.unavailable {
            opacity: .5;
            cursor: default;
          }
        }
      }
    }

    .registration {
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      padding-top: 12px;
      border-top: 1px solid border-color(neutrallight);

      & > span {
        font-family: font-family(secondary);
        font-size: rem(12);
      }

      &.switch {
        position: relative;
        display: inline-block;
        width: 36px;
        height: 18px;
        margin-left: 10px;

        input {
          opacity: 0;
          width: 0;
          height: 0;
        }
      }

      .slider {
        position: relative;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        height: 18px;
        max-width: 36px;
        margin: 0;

        &.selected {
          background-color: #38C14B;

          &:after {
            transform: translateX(16px);
          }
        }

        .round {
          &:before {
            border-radius: 50%;
          }
        }

        &:after {
          position: absolute;
          top: 1px;
          content: "";
          height: 14px;
          width: 14px;
          left: 2px;
          bottom: 2px;
          border-radius: 50%;
          background-color: bg-color(reverse);
          -webkit-transition: .4s;
          transition: .4s;
        }

        &.unavailable {
          & + span {
            opacity: 0.5;
          }
        }
      }

      input:checked + .slider {
        background-color: #38C14B;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px #38C14B;
      }

      input:checked + .slider:before {
        transform: translateX(18px);
      }

      .slider.round {
        border-radius: 18px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }

    .removefilter {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      text-align: center;
      text-decoration: none;
      background-color: bg-color(reverse);
      border: 1px solid border-color(neutrallight);
      padding: 6px;
      margin-bottom: 10px;
      @include icon(close, after, false);
      position: relative;

      &:after {
        position: absolute;
        right: 10px;
      }
    }

    .sticky_sentinel {
      background: yellow;
      position: absolute;
      left: 0;
      right: 0; /* needs dimensions */
      visibility: hidden;
    }

    .sticky_sentinel--top {
      /* Adjust the height and top values based on your on your sticky top position.
      e.g. make the height bigger and adjust the top so observeHeaders()'s
      IntersectionObserver fires as soon as the bottom of the sentinel crosses the
      top of the intersection container. */
      background: yellow;
      position: absolute;
      left: 0;
      right: 0; /* needs dimensions */
      visibility: hidden;
      height: 40px;
      top: -24px;
    }

    .sticky_sentinel--bottom {
      background: yellow;
      position: absolute;
      left: 0;
      right: 0; /* needs dimensions */
      visibility: hidden;
      bottom: 0;
      /* Height should match the top of the header when it's at the bottom of the
      intersection container. */
      height: calc(var(--header-height) + var(--default-padding));
    }

    .filter-utils {
      display: none;

      .show-results {
      }
    }

    .hide {
      display: none;
    }

  }

  .mobile-filter-close {
    display: none;
    @include icon(close, after, false);
  }

  .mobile-filter-open {
    display: none;
    font-family: font-family(secondary);
    font-size: rem(14);
    @include icon(filter, after, false);
  }

  .resultsnumber {
    margin-bottom: 8px;
  }

  .main-content {
    visibility: hidden;
  }
}