nav.main {
  p {
    font-size: rem(14);
    margin-top: 10px;
  }

  a {
    color: text-color(neutralmedium);

    &.handler {
      font-weight: 600;

      &:hover {
        color: text-color(primary);
      }
    }
  }

  .submenu {
    .highlight {
      position: relative;
      padding-left: 8px;

      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 1px;
        bottom: 1px;
      }

      &:after {
        width: 1px;
        left: 0;
      }

      &:before {
        left: 3px;
        width: 3px;
      }

      &.filosofia {
        &:after,
        &:before {
          background-color: bg-color(filosofia);
        }
      }

      &.psicologia {
        &:after,
        &:before {
          background-color: bg-color(psicologia);
        }
      }

      &.medicina {
        &:after,
        &:before {
          background-color: bg-color(medicinalight);
        }
      }
    }
  }

  .cta-button {
    display: flex;
    align-items: center;
    font-weight: 700;

    a {
      height: 50px;
      justify-content: center;
      color: text-color(primary);
      background-color: bg-color(neutrallight);

      &.button-secondary {
        background-color: bg-color(reverse);
        border: 2px solid palette(border, neutrallight);
      }
    }
  }

  .card-column {
    display: block;
  }
}

nav.utils {
  .submenu {
    text-align: left;

    a {
      @include icon(external, after);
      display: flex;
      justify-content: space-between;

      &:after {
        color: text-color(medicina);
      }
    }
  }

  .external {
    @include icon(chevron, after);
    font-family: font-family(secondary);
    color: text-color(neutralmedium);
    line-height: 1.618;
    border: 0;
    background-color: transparent;

    &:after {
      background-color: bg-color(neutralverylight);
      color: text-color(medicina);
      height: 30px;
      width: 30px;
      text-align: center;
      line-height: 30px;
      padding-left: 1px;
      font-size: rem(10);
    }
  }

  .contacts {
    a {
      @include icon(mail, after);

      &:after {
        color: text-color(psicologia);
      }
    }
  }

  .faq {
    a {
      @include icon(faq, after);

      &:after {
        font-size: rem(26);
        color: text-color(medicina);
      }
    }
  }

  .lang-eng {
    &:after {
      content: "EN";
      color: text-color(filosofia);
      font-size: rem(10);
    }
  }

  .lang-ita {
    &:after {
      content: "IT";
      color: text-color(filosofia);
      font-size: rem(10);
    }
  }

  .intranet {
    a {
      @include icon(external, after);

      &:after {
        color: text-color(medicina);
        line-height: 30px;
      }
    }
  }

  .alumni {
    a {
      @include icon(student, after);

      &:after {
        color: text-color(filosofia);
        font-size: rem(25);
      }
    }
  }

  .unisr-plus {
    a {
      @include icon(unisr_plus, after);

      &:after {
        color: text-color(psicologia);
        font-size: rem(25);
      }
    }
  }

  li:not(.intranet):not(.has-submenu) {
    & > a, button {
      &:after {
        background-color: bg-color(neutralverylight);
        height: 30px;
        width: 30px;
        text-align: center;
        line-height: 30px;
        padding-left: 1px;
      }

    }

    &.faq > a {
      &:after {
        font-size: rem(26);
      }
    }
  }
}

.mobile-only {
  display: none;
}