.card, .menucard, .slidecard, .bottomcard, .newscard, .editorialcard, .editorialcard-hp {
  padding-bottom: 30px;

  &.in-carousel {
    padding-bottom: 0;

    h5 {
      overflow: hidden;
    }
  }

  figure {
    background-color: #fff;
    border-radius: .25rem;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  picture {
    height: 0;
    overflow: hidden;
    padding-top: calc(270 / 380 * 100%);
    position: relative;

    > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
  }

  figcaption {
    padding: 30px 15px 30px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h5 {
    font-size: rem(18px);
    margin-bottom: 10px;
    line-height: 24px;
    margin-top: 0;
  }

  p {
    font-size: rem(16);
    margin-top: 0;
    margin-bottom: 0;
  }

  .button-primary {
    font-family: font-family(secondary);
    font-weight: 700;
    line-height: 16px;
    margin-top: 10px;
    padding: 6px 0 6px 0;

    &:after {
      background-color: bg-color(neutrallight);
      height: 23px;
      width: 23px;
      line-height: 23px;
      text-align: center;
      //color: white;
      font-size: 8px;
      vertical-align: middle;
      //padding: 5px 0;
      margin-left: 30px;
    }
  }

  .t-unisr-docenti-listing & {
    &:not(.show) {
      display: none;
    }

    picture {
      padding-top: 100%;
    }

    img {
      padding: 8%;
    }

    figcaption {
      padding-top: 0;
      text-align: center;

      a {
        font-size: rem(14);
        color: palette(text, primary);
        text-decoration: none;
        font-weight: 500;
      }
    }

    .social-link {
      a {
        color: palette(text, neutralmedium);
        padding: 5px;
        font-size: rem(14);

        &:hover {
          color: palette(text, brand);
        }
      }
    }
  }

  .t-unisr-docenti-listing.medicina & {
    .social-link {
      a:hover {
        color: palette(text, medicinalight);
      }
    }
  }

  .t-unisr-docenti-listing.psicologia & {
    .social-link {
      a:hover {
        color: palette(text, psicologia);
      }
    }
  }

  .t-unisr-docenti-listing.filosofia & {
    .social-link {
      a:hover {
        color: palette(text, filosofia);
      }
    }
  }

  &.teacher {

    figure {
      &:hover {
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, .3);;
      }
    }

    picture {
      padding-top: 100%;
    }

    .social-link {
      .social-text {
        word-break: break-all;

        a {
          color: palette(text, neutraldark);
          font-size: rem(14);
          line-height: 18px;
          text-decoration: none;
        }
      }

      i {
        display: inline-block;
        margin-bottom: 7px;
        position: relative;
        padding-left: 6px;

        &:before {
          color: palette(text, neutralmedium);
          font-size: rem(10);
          width: 23px;
        }
      }
    }

    figcaption {
      padding: 10px;
    }
  }
}

.facultycard, .slidecard, .bottomcard, .editorialcard, .editorialcard-hp {
  figure {
    padding-left: 70px;

    h3 {
      margin-top: 5px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      &:before {
        content: "";
        width: 50px;
        left: 15px;
        position: absolute;
        background-color: bg-color(neutrallight);
        height: 2px;
      }
    }
  }
}

.editorialcard, .editorialcard-hp {
  text-decoration: none;

  &.col-md-3 {
    figure {
      padding-left: 0;

      figcaption {
        padding-left: 70px;
      }
    }
  }
}

.menucard {
  display: flex;
  flex-direction: column;

  padding-left: 0;

  h3 {
    margin-top: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    background-color: bg-color(reverse);
  }

  p {
    flex-grow: 1;
  }

  .button-secondary {
    @include icon(chevron, after, false);
    position: relative;
    font-family: font-family(secondary);
    font-weight: 600;
    font-size: rem(14);
    padding-top: 10px;
    padding-right: 40px;
    padding-bottom: 10px;

    .card-column:hover & {
      background-color: bg-color(neutrallight);
    }

    &:after {
      position: absolute;
      right: 7px;
      top: 50%;
      transform: translateY(-50%);
      height: 23px;
      width: 23px;
      line-height: 23px;
      text-align: center;
      font-size: rem(14);
      vertical-align: middle;
      margin-left: 30px;
    }
  }
}

.slidecard {
  font-family: Poppins;
  background-color: bg-color(reverse);
  border-radius: .25rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 70px;
  height: 100%;
  display: flex;
  flex-direction: column;

  h3 {
    margin-top: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &:before {
      content: "";
      width: 50px;
      left: 0;
      position: absolute;
      background-color: bg-color(neutrallight);
      height: 2px;
    }
  }

  p {
    font-family: font-family(secondary);
    font-size: rem(14);
  }

  a {
    font-size: rem(14);
  }
}

.bottomcard {
  padding-bottom: 0;

  figure {
    figcaption {
      justify-content: flex-start;
    }

    h3 {
      color: palette(text, primary);
      font-size: rem(27);
    }

    p {
      color: palette(text, primary);
      font-size: rem(14);
    }

    a {
      color: palette(text, primary);
      font-size: rem(14);
      font-weight: 700;
      text-align: center;
      padding: 20px 0;
      margin-top: 20px;
      width: 100%
    }
  }
}

.newscard {
  figcaption {
    position: relative;

    h5 {
      padding-top: 10px;
    }

    span {
      position: absolute;
      top: -15px;
      background: #575757;
      color: white;
      left: 20px;
      text-align: center;
      padding: 7px 10px;
      font-family: font-family(secondary);
      font-size: rem(14);
      font-weight: 400;
      line-height: 16px;

      small {
        padding-top: 10px;
      }
    }
  }

  &.newscard-cat-list {
    figure {
      padding-left: 29px;

      & > div {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        .dots {
          display: flex;
          flex-direction: row;
          position: absolute;
          left: -21px;
          z-index: 1;

          .dot {
            height: 8px;
            width: 8px;
            background-color: #bbb;
            border-radius: 50%;
            display: inline-block;
            margin-right: 9px;

            &.filosofia {
              background-color: palette(text, filosofia);
            }

            &.medicina {
              background-color: palette(text, medicina);
            }

            &.psicologia {
              background-color: palette(text, psicologia);
            }
          }
        }

        h2 {
          position: absolute;
          color: white;
          padding-left: 41px;
          margin: 0;
          z-index: 1;
        }

        picture {
          height: auto;
        }
      }

      figcaption {
        justify-content: flex-start;
        padding: 0 0 30px;
        height: 100%;

        .with-arrow {
          &:after {
            color: text-color(reverse);
            background-color: bg-color(neutraldark);
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        ul {
          list-style: none;
          flex-grow: 1;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: 25px;

          li {
            margin-bottom: 25px;
            line-height: 16px;
            vertical-align: middle;

            &:last-child {
              margin-bottom: 0;
            }

            a {
              text-decoration: none;
              font-family: font-family(primary);
            }
          }
        }

        div {
          padding-left: 41px;

          a {
            font-size: rem(14);
            line-height: rem(20);
            padding-right: 35px;
          }
        }
      }
    }
  }

  figure {
    max-width: 310px;
    picture {
      height: auto;
      overflow: initial;
      position: relative;
      padding: 0;
      margin: 0;
      flex-shrink: 0;

      img {
        position: relative;
        vertical-align: middle;
      }
    }

    figcaption {
      justify-content: flex-start;
      padding-bottom: 35px;
      padding-left: 20px;
      height: auto;
    }
  }
}

.facultycard {
  .philosophy-icon {
    @include icon(arrow, before);
  }

  .medicine-icon {
    @include icon(arrow, before);
  }

  .psycology-icon {
    @include icon(arrow, before);
  }
}

.c-editorial-cards {
  .container {
    padding: 0;
  }

  .editorialcard, .editorialcard-hp {
    figure {
      figcaption {
        .with-arrow {
          &:after {
            color: text-color(primary);
          }
        }
      }
    }
  }
}

.c-governance-cards {
  .container {
    .governance-card {
      .governance-card_title {
        text-align: center;
        margin: 0 0 20px;
      }

      .governance-card-item__name {
        padding-bottom: 0;
        margin-top: 19px;
        margin-bottom: 0;
      }
    }
  }
}

.t-unisr-docenti-listing {
  figcaption {
    padding-right: 0;
  }
}

.coursecard {
  margin-bottom: 30px;

  a {
    display: flex;
    flex-direction: column;
    position: relative;
    text-decoration: none;
    background-color: bg-color(reverse);
    width: 100%;
    height: 100%;
    padding-top: 6px;
    padding-right: 15px;
    padding-left: 35px;

    & > span {
      color: text-color(reverse);
      background-color: bg-color(primary);
      position: absolute;
      top: 0;
      right: 0;
      font-size: 12px;
      padding: 5px 15px;
    }

    &.filosofia {
      span, h5 {
        &:after, &:before {
          color: palette(text, filosofia);
        }
      }
    }

    &.medicina {
      span, h5 {
        &:after, &:before {
          color: palette(text, medicina);
        }
      }
    }

    &.psicologia {
      span, h5 {
        &:after, &:before {
          color: palette(text, psicologia);
        }
      }
    }

    &.filosofia {
      .course-info {
        h5 {
          &:after, &:before {
            background-color: palette(text, filosofia);
          }
        }
      }
    }

    &.medicina {
      .course-info {
        h5 {
          &:after, &:before {
            background-color: palette(text, medicina);
          }
        }
      }
    }

    &.psicologia {
      .course-info {
        h5 {
          &:after, &:before {
            background-color: palette(text, psicologia);
          }
        }
      }
    }

    .course-info {
      flex-grow: 1;
      height: 100%;

      small {
        font-family: font-family(secondary);
        font-size: 10px;
        text-transform: uppercase;
      }

      h5 {
        margin-top: 5px;
        margin-bottom: 10px;
        position: relative;
        align-items: center;

        &:first-child {
          &:last-child {
            margin: 0;
          }
        }

        &:before {
          content: "";
          width: 20px;
          left: -35px;
          top: 11px;
          position: absolute;
          background-color: bg-color(neutrallight);
          height: 2px;
        }
      }
    }

    .extra-info {
      border-top: 1px solid border-color(neutrallight);

      p {
        font-family: font-family(secondary);
        display: flex;

        span {
          display: flex;
          align-items: center;
          font-size: 12px;
          margin-right: 13%;

          &:before {
            font-size: 16px;
            padding-right: 10px;
          }

          &.filosofia {
            &:after, &:before {
              color: palette(text, filosofia);
            }
          }

          &.medicina {
            &:after, &:before {
              color: palette(text, medicina);
            }
          }

          &.psicologia {
            &:after, &:before {
              color: palette(text, psicologia);
            }
          }

          &.credits-icon {
            @include icon(credits, before);
          }

          &.language-icon {
            @include icon(language, before);
          }

          &.time-icon {
            @include icon(time, before);
          }
        }
      }
    }
  }
}

.edge-15,
.edge-16 {
  .t-unisr-docenti-listing .card {
    picture {
      height: auto;
      position: static;
      padding-top: initial;
      overflow: unset;
    }

    img {
      position: static;
      height: inherit;
    }
  }
}