.modal-container {
  display: none;

  &.not-collapsed {
    min-height: 1px;
    display: flex;
    position: fixed;
    z-index: $backdrop-zindex;
    background-color: rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .modal-content-container {
      position: relative;
      padding: 30px 60px 30px 30px;
      max-width: 690px;
      max-height: 80vh;
      overflow: hidden;
      overflow-y: auto;

      .modal-close {
        position: absolute;
        cursor: pointer;
        right: 0;
        top: 0;
        height: 40px;
        width: 40px;
        background-color: $neutral;
        padding: 10px;
        text-align: center;
      }
    }

    &.target-modal {
      flex-direction: column;
      background-color: bg-color(neutral);
      overflow-y: auto;

      .c-editorial-cards {
        padding: 80px 0;

        & > h2 {
          top: 52px;
          position: absolute;
          transform: translateX(-50%);
          left: 50%;

          &:before, &:after {
            content: none;
          }
        }

        & > h6 {
          top: 0;
          position: absolute;
          transform: translateX(-50%);
          left: 50%;

          &:before, &:after {
            content: none;
          }
        }

        .editorialcard, .editorialcard-hp {
          min-width: 1px;

          picture {
            &:before, &:after {
              content: none;
            }
          }
        }
      }

      .header-logo {
        background-color: bg-color(reverse);
        padding: 50px 0 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-height: 155px;
        min-width: 100vw;

        .container {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .icon-close {
        cursor: pointer;
        color: text-color(reverse);
        background-color: bg-color(primary);
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: rem(25);
      }
    }
  }
}