footer.main {
  .handler-accordion {
    &:after {
      display: none;
    }
  }

  .lower-bar {
    .row {
      align-items: center;

      div:first-child {
        padding-left: 0;
      }

      div:last-child {
        padding-right: 0;
      }
    }

    ul {
      text-align: right;
    }
  }

  .target-accordion {
    li {
      margin-bottom: 24px;

      a {
        font-family: font-family(primary);
        font-size: rem(12);
      }
    }
  }

  .upper-bar {
    h3:not(.customer-care) {
      a {
        font-size: rem(12);
      }
    }
  }

  .newsletter-btn {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .social-icon {
    margin-bottom: 0;

    li {
      text-align: right;
      padding: 0 3px 10px 3px;

      a {
        width: 30px;
        height: 30px;

        &:hover {
          color: palette(text, reverse);
        }

        &.icon {
          &:before {
            font-size: 10px;
          }
        }
      }
    }
  }

  .customer-care {
    a {
      cursor: default;
      pointer-events: none;
    }
  }

  .last {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

}