.blog-carousel, .news-carousel, .events-carousel {
  .carousel-menu {
    h2 {
      line-height: 51px;
      padding-right: 60px;
      margin: 0;
    }

    .scroll-menu {
      overflow: auto;
      white-space: nowrap;
      padding-bottom: 20px;
    }

    a {
      padding-left: 16px;

      &:first-of-type {
        padding-left: 0;
      }
    }
  }

  .tns-item {
    padding-left: 0;
    padding-right: 12px;
  }

  .tns-controls {
    display: none;
  }
}