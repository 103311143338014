.c-banner-faculty {
  display: flex;
  flex-wrap: wrap;

  .facultycard {
    a {
      width: 100%;
      height: 100%;
      display: flex;
      //flex-direction: column;
      align-items: center;
      background: white;
      padding: 25px 30px 25px 40px;
      margin-top: 0;

      &:after {
        width: 40px;
        height: 40px;
        line-height: 40px;
        margin-left: 0;
        font-size: rem(16);
      }

      figure {
        width: 100%;
        //padding: 0 0 18px;
        padding: 0;

        picture {
          //padding-top: 18%;
          display: flex;
          align-items: center;

          img {
            width: auto;
            height: 64px;
          }
        }
      }
    }

    .with-arrow {
      &:after {
        background-color: bg-color(primary);
        color: text-color(reverse);
        text-align: center;
        margin-left: 10px;
      }
    }

    &.filosofia {
      h3, .with-arrow {
        &:before, &:after {
          background-color: palette(text, filosofia);
        }
      }
    }

    &.medicina {
      h3, .with-arrow {
        &:before, &:after {
          background-color: palette(text, medicina);
        }
      }
    }

    &.psicologia {
      h3, .with-arrow {
        &:before, &:after {
          background-color: palette(text, psicologia);
        }
      }
    }

    a {
      font-size: rem(14);
    }
  }

  & > h2 {
    flex-grow: 1;
    margin-top: 0;
  }

  & > a {
    display: flex;
    align-items: center;
    font-size: rem(14);
    margin-bottom: 26px;
  }

  picture {
    overflow: initial;
  }

  .cta {
    &.with-arrow {
      font-weight: 700;
      padding-right: 0;
      padding-left: 0;

      &:after {
        background-color: bg-color(reverse);
        color: text-color(primary);
        height: 40px;
        width: 40px;
        text-align: center;
        font-size: 16px;
        vertical-align: middle;
        line-height: 40px;
        padding-left: 2px;
        margin-left: 30px;
      }
    }
  }
}