.c-editorial-cards {
  &.with-bg {

    h6 {
      font-size: rem(12);
      text-transform: uppercase;
    }

    .editorialcard, .editorialcard-hp {
      padding-bottom: 0;

      .button-primary {
        padding-right: 54px;

        &:after {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .target-modal & {
        h3 {
          &:before {
            background-color: bg-color(neutrallight);
          }
        }
      }
    }
  }
}