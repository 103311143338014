.blog-carousel {

  figure {
    width: 150px;

    .blog-image  {
      height: 80px;
    }

    figcaption {
      height: 160px;
      padding: 8px 10px 44px;

      small {
        padding-bottom: 10px;
      }

      h5 {
        padding-top: 0;
        line-height: 20px;
      }
    }
  }
}