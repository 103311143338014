.t-unisr-search-results {

  .main-content {
    padding-bottom: 170px;
    padding-top: 6px;
  }

  h4 {
    font-weight: normal;
  }

  .search-results-wrapper {
    position: relative;
    margin-bottom: 40px;
    width: 100%;

    .search-results-item {
      display: flex;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        @include icon(chevron, after);
        color: palette(text, primary);
        background-color: bg-color(reverse);
        font-size: rem(22);
        font-weight: 700;
        text-decoration: none;
        position: relative;
        word-break: break-word;
        transition: text-shadow .1s;
        padding: 16px 120px 16px 30px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-grow: 1;
        flex-direction: column;
        display: inline;


        &:after {
          color: text-color(primary);
          background-color: bg-color(neutrallight);
          height: 22px;
          width: 22px;
          text-align: center;
          line-height: 22px;
          padding-left: 1px;
          font-size: rem(10);
          position: absolute;
          right: 30px;
          top: 50%;
          transform: translateY(-50%);

        }

        span {
          font-size: rem(12);
          font-weight: normal;
          display: block;
        }
      }

      .header-search-text {
        width: 85%;
        height: 35px;
        padding: 6px 14px;
      }

      .header-search-submit {
        width: 15%;
        padding-bottom: 8px;
      }
    }
  }
}