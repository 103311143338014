.title-banner {
  background-position: center;
  background-size: cover;
  color: text-color(reverse);
  min-height: 272px;
  display: flex;
  align-items: center;

  &:not(.bg-img) {
    background-color: bg-color(neutraldark);

    .filosofia &,
    &.filosofia {
      background-color: bg-color(filosofia);
    }

    .medicina &,
    &.medicina {
      background-color: bg-color(medicinalight);
    }

    .psicologia &,
    &.psicologia {
      background-color: bg-color(psicologia);
    }
  }

  .subtitle {
    display: block;
    font-weight: 500;
  }

  .is-teacher-page {
    .subtitle {
      font-weight: 300;
      font-size: rem(14);
    }
  }

  .course-info-list {
    font-family: font-family(secondary);
    font-size: rem(14);
    color: text-color(reverse);
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-flex;
      align-items: center;
      position: relative;
      padding-right: 14px;
      margin-right: 14px;

      &:not(:last-child) {
        &:after {
          content: "";
          background: bg-color(reverse);
          width: 1px;
          height: 14px;
          position: absolute;
          right: 0;
        }
      }
    }
  }

  .wrapper-banner {
    & > a {
      color: text-color(reverse);
      @include icon(chevron, before);
      text-decoration: none;

      &:before {
        transform: rotate(180deg);
        color: #fff;
        height: 22px;
        width: 22px;
        text-align: center;
        line-height: 22px;
        padding-left: 1px;
        font-size: 1rem;
        margin-right: 15px;

        .filosofia & {
          background-color: palette(text, filosofia);
        }

        .medicina & {
          background-color: palette(text, medicina);
        }

        .psicologia & {
          background-color: palette(text, psicologia);
        }
      }

      & + h1 {
        margin: 10px 0;
      }
    }

    h1 {
      margin-top: 0;
      color: text-color(reverse);

      & + course-info-list {
        margin-top: 20px;
      }
    }
  }

}