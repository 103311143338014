.modal-calendar-item {
  .modal-content-container {
    background-color: bg-color(reverse);
  }

  .calendar-details-day {
    margin-top: 0;
    font-size: 12px;
    color: text-color(brand);
    padding-left: 10px;
    text-align: center;
    margin-bottom: 0;
  }

  .calendar-events-list {
    padding: 0;
    margin-bottom: 0;
    margin-top: 0;
  }

  .calendar-event {
    border-bottom: 1px solid palette(border, brand);
    padding-bottom: 25px;
    list-style: none;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    &:first-child {
      .event-title {
        margin-top: 0;
        font-size: 23px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 26px;
      }
    }
  }

  .event-title {
    color: text-color(brand);
    font-size: 1.6rem;
    margin-bottom: 15px;
    line-height: 22px;
  }

  .event-data {
    list-style: none;
    padding: 0;

    li {
      display: inline-flex;
      align-items: center;
      font-family: font-family(secondary);
      font-size: 1.4rem;
      position: relative;

      span {
        position: relative;
        width: 40px;
        height: 40px;
        text-align: center;
        padding: 10px;

        &.icon-calendar {
          color: text-color(medicina);
          background-color: lighten(bg-color(medicinalight), 16%);
        }

        &.icon-clock {
          color: text-color(psicologia);
          background-color: lighten(bg-color(psicologialight), 8%);
        }

        &.icon-pin {
          color: text-color(filosofia);
          background-color: lighten(bg-color(filosofialight), 16%);
        }
      }

      p {
        display: inline;
        font-family: font-family(secondary);
        letter-spacing: 0;
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }

  .event-description {
    position: relative;
    font-family: font-family(secondary);
    letter-spacing: 0;
    line-height: 30px;
    margin-top: 32px;

    &::before {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      background-color: bg-color(neutral);
      top: -15px;
    }

    p {
      font-family: font-family(secondary);
    }
  }

  .subscribe-button {
    width: 305px;
    height: 50px;
    display: flex;
    justify-content: center;
    background-color: bg-color(medicina);
    color: text-color(reverse);
    align-items: center;
    font-size: rem(14);
    font-weight: bold;
    letter-spacing: 0;
    text-transform: uppercase;
  }
}