/*  ===GRID GOLBAL SETTINGS=== */

/**
 * The maps below have sampling purposes.
 * Customize or remove them if not required
 * This map overrides the one definied by Bootstrap
 * so you have to keep the name
 */

$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 992px,
  lg: 1455px
);

/**
 * Use this map if you need to change width and gutter
 * according to the breakpoint.
 * Then add custom style to layouts/l_pillars.scss into media query
 */

// $container-width: 95%;

$container-max-widths: (
  sm: 1170px
);

$grid-gutter-widths: (
  XS: 12px,
  SM: 12px,
  MD: 14px,
  LG: 14px
);

$container-paddings: (
  XS: 16px,
  SM: 18px,
  MD: 20px,
  LG: 22px
);

$xs: map($grid-breakpoints, xs);
$sm: map($grid-breakpoints, sm);
$md: map($grid-breakpoints, md);
$lg: map($grid-breakpoints, lg);

$grid-columns:                12;
$grid-gutter-width:           30px;
