.handler-accordion {
  position: relative;
  @include icon(minus_accordion, after, false); 
  
  &:after {
    transition: transform .5s cubic-bezier(0.55, 0.09, 0.68, 0.53);
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    font-size: rem(12);
  }
  
  &.collapsed {
    @include icon(plus_accordion, after, false); 
  }
}

