
nav.main {
  > ul {
    > li {
      > a {
        font-size: rem(11);
        padding: 10px 5px;
      }
    }
  }

  .submenu {
    font-size: rem(11);
  }

  .has-submenu {
    > a:after {
      padding-bottom: 5px;
    }
  }
}
