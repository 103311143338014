.filters-container{
  margin-bottom: 30px;
}
.filter {
  &.docenti {
    margin-bottom: 10px;
    .handler-smalldown {
      display: none;
    }

    button {
      margin-right: 10px;
      font-size: rem(14);

      &:hover {
        border-bottom: 2px solid;
      }

      &.hide-smalldown {
        display: none;
      }

      &.all {
        position: absolute;
        left: 0;
        top: -2px;
        z-index: 1;
        padding: 5px 10px;
      }
    }

    div {
      position: relative;
  
      &.wrap {
        padding-left: 70px;
      }
    }
  }
}