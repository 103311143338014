.t-unisr-research {
  .research-introduction {
    margin-bottom: 40px;
    & > h2 {
      margin: 25px 0 0 0;
    }
    .col-md-6 {
      :last-child {
        margin-bottom: 0;
      }
    }
  }
  .c-grid-news {
    border-bottom-style: none;
    &.widget {
      margin-bottom: 40px;
    }
    & > a {
      order: 3;
      width: 100%;
      justify-content: space-between;
      margin-top: 4px;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .newscard {
      padding-bottom: 10px;
      &:nth-child(odd) {
        padding-right: 5px;
        padding-left: 10px;
      }
      &:nth-child(even) {
        padding-right: 10px;
        padding-left: 5px;
      }
      &:last-child {
        display: initial;
      }
    }
  }
}