footer.main {
  h3, h4, h5, p, ul, li, a, small, span {
    color: text-color(reverse);
    font-size: rem(12);
  }
  a {
    text-decoration: none;
  }

  .upper-bar {
    background-color: bg-color(neutraldark);

    .container {
      max-width: 1440px;
    }
  }

  .lower-bar {
    .container {
      border-top: 2px solid border-color(reverse);
    }

    a {
      border-right: 1px solid;
      padding: 0 10px;
      font-family: font-family(primary);
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: inline-block;
        &:last-of-type {
          a {
            border-right: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
  .newsletter-btn {
    text-align: center;
    background: bg-color(transparent);
    border-color: palette(border, reverse);
  }
  .social-icon {
    padding: 0;
    list-style: none;
    li {
      display: inline-block;

      a {
        padding: 0;
        border: 1px solid;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        font-size: rem(14);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .target-accordion {
    padding-left: 0;
    margin-top: 5px;
    li {
      list-style-type: none;
    }
  }

  .customer-care {
    text-transform: uppercase;
    margin-bottom: 0;
    span {
      display: block;
      margin-top: 16px;
      a {
        font-size: rem(21);
        font-family: font-family(secondary);
        font-weight: 400;
      }
    }
  }
  .follow-on-social {
    margin-bottom: 10px;
  }
}