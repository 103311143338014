.c-story-card-component {
  .c-story-card {
    figure {
      flex-direction: column;

      .wrap-img {
        width: 100%;
        padding-top: 72.758%;
      }

      .wrap-text {
        padding: 30px;

        blockquote {
          position: relative;
          padding-right: 0;

          &:before {
            top: -22px;
            left: 0;
          }

          h3 {
            position: relative;
            padding-right: 0;
          }
        }
      }
    }
  }
}