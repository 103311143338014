.scroll-to-top {
  opacity: 1;
  right: 10px;
  width: 30px;
  height: 30px;
  bottom: 10px;
  position: fixed;
  cursor: pointer;
  background: bg-color(reverse);
  border-radius: 30px;
  z-index: $backtotop-zindex;
  transition: all .4s linear;
  box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
  .icon-chevron {
    display: block;
    color: text-color(primary);
    line-height: .7;
    font-size: rem(12);
    transform: rotate(-90deg);
  }
  &.hidden {
    opacity: 0;
    z-index: -1;
  }
  &:hover {
    width: 50px;
    height: 50px;
    background: bg-color(primary);
    .icon-chevron {
      color: text-color(reverse);
    }
  }
}