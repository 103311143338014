button {
  @include font(primary, 300);
}

.button-primary {
  padding: 6px 14px;
  text-decoration: none;
  display: inline-block;

  &.right {
    float: right;
    clear: both;
  }

  &.with-arrow {
    position: relative;
    @include icon(chevron, after);

  }

  &.filosofia {
    background-color: buttons(filosofia, background-color);
    border-color: buttons(filosofia, border-color);
    color: buttons(filosofia, color);

    &:hover,
    &:focus:hover {
      background-color: buttons(filosofia, hover-background-color);
      border-color: buttons(filosofia, hover-border-color);
      color: buttons(filosofia, hover-color);
    }

    &:active,
    &:active:focus,
    &:focus {
      background-color: buttons(filosofia, active-background-color);
      border-color: buttons(filosofia, active-border-color);
      color: buttons(filosofia, active-color);
    }
  }

  &.full {
    width: 100%;
  }

  &.medicina {
    background-color: buttons(medicina, background-color);
    border-color: buttons(medicina, border-color);
    color: buttons(medicina, color);

    &:hover,
    &:focus:hover {
      background-color: buttons(medicina, hover-background-color);
      border-color: buttons(medicina, hover-border-color);
      color: buttons(medicina, hover-color);
    }

    &:active,
    &:active:focus,
    &:focus {
      background-color: buttons(medicina, active-background-color);
      border-color: buttons(medicina, active-border-color);
      color: buttons(medicina, active-color);
    }
  }

  &.psicologia {
    background-color: buttons(psicologia, background-color);
    border-color: buttons(psicologia, border-color);
    color: buttons(psicologia, color);

    &:hover,
    &:focus:hover {
      background-color: buttons(psicologia, hover-background-color);
      border-color: buttons(psicologia, hover-border-color);
      color: buttons(psicologia, hover-color);
    }

    &:active,
    &:active:focus,
    &:focus {
      background-color: buttons(psicologia, active-background-color);
      border-color: buttons(psicologia, active-border-color);
      color: buttons(psicologia, active-color);
    }
  }
}

.button-secondary {
  border: 3px solid;
  background-color: buttons(secondary, background-color);
  border-color: buttons(secondary, border-color);
  color: buttons(secondary, color);
  padding: 6px 14px;
  text-decoration: none;
  display: inline-block;

  &:hover,
  &:focus:hover {
    background-color: buttons(secondary, hover-background-color);
    border-color: buttons(secondary, hover-border-color);
  }

  &:active {
    background-color: buttons(secondary, active-background-color);
    border-color: buttons(secondary, active-border-color);
    color: buttons(secondary, color);
  }
}

.button-download {
  @include icon(download, after);
  padding: 14px 46px;
  border: 1px solid palette(border, reverse);
  margin-bottom: 5px;
  font-size: rem(12);
  text-decoration: none;
  background-color: bg-color(reverse);
  color: palette(text, primary);
  line-height: 14px;
  display: flex;
  align-items: center;
  position: relative;

  &:first-of-type {
    //margin-top: 20px;
  }

  &:not(:first-of-type) {
    border-top: 1px solid border-color(neutrallight);
  }

  &.pdf {
    @include icon(pdf, before);
  }

  &.xlsx,
  .xls {
    @include icon(xls, before);
  }

  &.docx,
  .doc {
    @include icon(doc, before);
  }

  &:before,
  &:after {
    color: palette(text, medicina);
  }

  &:before {
    font-size: rem(24);
    position: absolute;
    left: 3px;
  }

  &:after {
    font-size: rem(14);
    position: absolute;
    right: 15px;
  }

  &:hover {
    background-color: bg-color(neutrallight);
  }

  .medicina & {
    &:before,
    &:after {
      color: palette(text, medicinadark);
    }

    &:hover {
      background-color: bg-color(medicinalight);
    }
  }

  .filosofia & {
    &:before,
    &:after {
      color: palette(text, filosofia);
    }

    &:hover {
      background-color: bg-color(filosofia);
    }
  }

  .psicologia & {
    &:before,
    &:after {
      color: palette(text, psicologiadark);
    }

    &:hover {
      background-color: bg-color(psicologia);
    }
  }
}

table {
  td > .button-download:first-of-type {
    margin-top: 0;
  }
}